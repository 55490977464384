<template>
  <div class="upload-pack">
    <a-upload
      list-type="picture"
      name="file"
      :multiple="false"
      :file-list="form[field]"
      :customRequest="handleUploadImagePack"
      :remove="handleRemove"
      :before-upload="handleBeforeUpload"
      :disabled="isUpload"
      :showUploadList="{ showPreviewIcon: 'profile', showRemoveIcon: true }"
    >
      <a-progress v-if="isUpload" :show-info="false" :percent="percent" />
      <div v-if="limit > 0 && form[field].length < limit">
        <a-button icon="upload">上传图包</a-button>
      </div>

      <div v-if="limit <= 0">
        <a-button icon="upload">上传图包</a-button>
      </div>
    </a-upload>
  </div>
</template>

<script>
import { netImagePack, uploadToOSS } from '@/utils/image'
import { getOSSToken } from '@/utils/oss'

export default {
  name: 'UploadPack',

  data () {
    return {
      // 是否上传
      isUpload: false,
      // 上传进度
      percent: 0
    }
  },

  props: {
    form: { type: Object, required: true },
    field: { type: String, required: true },
    limit: { type: Number, default: -1 }
  },

  methods: {
    // 自定义上传图片逻辑
    handleUploadImagePack ({ file }) {
      // 显示进度条
      this.isUpload = true
      this.percent = 1
      const speed = 100 / (file.size / 65000)
      const uploading = setInterval(() => {
        if (this.percent < 100) {
          this.percent += speed
        }
      }, 100)

      getOSSToken().then(ossToken => {
        if (ossToken) {
          uploadToOSS(file, ossToken)
            .then(url => {
              if (url) {
                clearInterval(uploading)
                this.isUpload = false
                this.form[this.field].push(netImagePack(this.form[this.field].length, url))
              } else {
                this.$message.error('上传失败')
              }
            })
            .catch(() => {
              this.isUpload = false
              this.$message.error('上传失败')
            })
        } else {
          this.isUpload = false
          this.$message.error('上传失败')
        }
      }).catch(() => {
        this.isUpload = false
        this.$message.error('上传失败')
      })
    },

    // 自定义删除图片逻辑
    handleRemove (file) {
      this.form[this.field].splice(file.uid, 1)
      // 重新校正一下uid
      for (const i in this.form[this.field]) {
        this.form[this.field][i].uid = i
      }
    },

    // 上传前拦截
    handleBeforeUpload (file) {
      const tempList = file.name.split('.')
      const suffix = tempList[tempList.length - 1]
      const isSuffixValid = /^rar|zip$/i.test(suffix)
      if (!isSuffixValid) {
        this.$message.error('只能上传压缩包')
      }
      // const isLt2M = file.size / 1024 / 1024 < 2
      // if (!isLt2M) {
      //   this.$message.error('Image must smaller than 2MB!')
      // }
      return isSuffixValid
    }
  }
}
</script>

<style>
.upload-pack .ant-upload-list-item-thumbnail {
  display: none;
  width: 0;
  height: 0;
}
.upload-pack .ant-upload-list-picture .ant-upload-list-item-name, .ant-upload-list-picture-card .ant-upload-list-item-name {
  padding-left: 0;
}
</style>
