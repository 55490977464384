<template>
  <div>
    <!-- 搜索栏 -->
    <a-row :style="{ marginBottom: '16px' }" type="flex" justify="space-between">
      <a-col></a-col>
      <a-col v-if="getPermission('add')">
        <a-button @click="handleShowModal(null)">添加</a-button>
      </a-col>
    </a-row>

    <!-- 表格 -->
    <Table
      :columns="columns"
      :data="list"
      :pageNum="pageNum"
      :pageSize="pageSize"
      v-model="total"
      @pageChange="handlePageChange"
      @pageSizeChange="handlePageSizeChange"
      @recordEdit="handleShowModal"
      @tableChange="handleTableChange"
    />

    <!-- 添加和编辑框 -->
    <token-modal
      ref="modal"
      :title="modalTitle"
      :visible="modalVisible"
      :loading="modalLoading"
      :form="form"
      :isEdit="isEdit"
      :userList="userList"
      @ok="handleAddOrEdit"
      @cancel="handleCloseModal"
    />
  </div>
</template>

<script>
import Table from '@/components/table/Table'
import page from '@/mixins/page'
import permission from '@/mixins/permission'
import status from '@/mixins/status'
import tool from '@/mixins/tool'
import TokenModal from './TokenModal'
import { copy, deepCopy } from '@/utils/object'

export default {
  name: 'TokenList',

  components: { Table, TokenModal },

  mixins: [permission, page, status, tool],

  data () {
    return {
      // 表格列
      columns: [
        {
          title: '代币来源',
          dataIndex: 'source',
          align: 'center',
          customRender: text => this.getSourceMap(text),
          filters: [
            { text: '系统', value: 'System' },
            { text: '退款', value: 'Refund' },
            { text: '抵扣支付', value: 'Paid' }
          ],
          onFilter: () => true,
          filterMultiple: false
        },
        { title: '数量', dataIndex: 'value', align: 'center', sorter: true },
        { title: '目标用户', dataIndex: 'user.name', align: 'center' },
        { title: '添加人', dataIndex: 'manager.name', align: 'center' },
        { title: '添加时间', dataIndex: 'createdAt', align: 'center', customRender: this.handleGetDateTime, sorter: true }
      ],

      // 用户数据
      userList: [],

      modalVisible: false,
      modalLoading: false,
      addModalTitle: '添加代币',
      editModalTitle: '编辑代币记录',
      isEdit: false,

      // 添加或编辑表单
      addForm: {
        source: 'System',
        value: 0,
        userID: undefined
      },
      editForm: {},
      form: {}
    }
  },

  computed: {
    modalTitle () {
      return this.isEdit ? this.editModalTitle : this.addModalTitle
    },
    // 获取列表的参数
    args () {
      return {
        token: {
          filter: this.filter,
          sort: this.sort
        },
        page: {
          pageNum: this.pageNum,
          pageSize: this.pageSize
        }
      }
    }
  },

  mounted () {
    this.getUserList()
    this.getTokenList()
  },

  methods: {
    // 搜索
    handleSearch () {
      this.getTokenList()
    },

    // 打开弹框
    handleShowModal (record = undefined) {
      if (record) {
        this.form = deepCopy(this.editForm)
        copy(this.form, record)
        this.isEdit = true
      } else {
        this.form = deepCopy(this.addForm)
        this.isEdit = false
      }
      this.modalVisible = true
    },

    // 关闭弹框
    handleCloseModal () {
      this.modalVisible = false
      // 去掉提示
      this.$refs.modal.$refs.form.resetFields()
    },

    // 添加或编辑
    handleAddOrEdit () {
      this.$refs.modal.$refs.form.validate(valid => {
        if (!valid) return
        if (this.isEdit) {
          // 不支持编辑
        } else {
          delete this.form.id
          this.handleAdd(deepCopy(this.form))
        }
      })
    },

    // 添加数据
    handleAdd (form) {
      this.api.addToken(form).then(res => {
        if (res.data.addToken.id) {
          this.$message.success('添加成功')
          this.getTokenList()
          this.handleCloseModal()
        }
      })
    },

    // 获取数据
    getTokenList () {
      this.api.getTokenList(this.args).then(res => {
        this.list = res.data.getTokenList.data
        this.total = res.data.getTokenList.total
      })
    },

    // 换页回调
    handlePageChange (pageNum, pageSize) {
      this.pageNum = pageNum
      this.pageSize = pageSize
      this.getTokenList()
    },

    // 页数回调
    handlePageSizeChange (pageNum, pageSize) {
      this.pageNum = pageNum
      this.pageSize = pageSize
      this.getTokenList()
    },

    // 表格回调，筛选、排序等
    handleTableChange (...payload) {
      this.handleFilter(...payload)
      this.handleSort(...payload)
      this.getTokenList()
    },

    // 获取用户数据
    getUserList () {
      this.api.getUserList({
        isAll: true
      }).then(res => {
        this.userList = res.data.getUserList.data
      })
    }
  }
}
</script>

<style scoped>

</style>
