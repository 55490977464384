<template>
  <div>
    <a-modal
      :title="title"
      :visible="visible"
      :confirm-loading="loading"
      @ok="handleOk"
      @cancel="handleCancel"
      :centered="true"
      :maskClosable="false"
      :width="800"
    >
      <a-form-model
        :rules="rules"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
        ref="form"
        :model="form"
      >
        <a-form-model-item prop="tags" label="相关标签">
            <a-select v-model="form.tags" placeholder="相关标签，打字可搜索" mode="multiple" :not-found-content="null"
                      show-search :filter-option="false" :show-arrow="false" @search="handleSearchTag">
            <a-select-option v-for="(item, index) of tagSelectOptionList" :value="item.id" :key="index">
              {{item.name}}
            </a-select-option>
          </a-select>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import permission from '@/mixins/permission'
import { deepCopy } from '@/utils/object'
import { removeDuplication } from '@/utils/array'
import status from '@/mixins/status'

export default {
  name: 'ImageModal',

  data () {
    return {
      labelCol: { span: 5 },
      wrapperCol: { span: 16 },
      // 表单验证规则
      rules: {
        tags: [
          { required: true, message: '请选择石材相关标签', trigger: ['blur', 'change'] }
        ]
      },

      // 用于Select的标签选项列表
      tagSelectOptionList: []
    }
  },

  mixins: [permission, status],

  props: {
    title: String,
    visible: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
    form: { type: Object, required: true }
  },

  watch: {
    visible: function (val) {
      // 刚进来先填充一下标签选择
      if (val === true) {
        // 如果是编辑就把编辑的也带上，和服务端返回的拼接
        const that = this
        const tags = deepCopy(this.form.tags.map(item => ({
          id: item.id,
          name: item.name
        })))
        this.form.tags = this.form.tags.map(item => item.id)
        // 如果是编辑则使用默认已选择的
        this.handleSearchTag('', function () {
          that.tagSelectOptionList = removeDuplication(that.tagSelectOptionList.concat(tags), 'id')
        })
      }
    }
  },

  methods: {
    // 确认
    handleOk () {
      this.$emit('ok')
    },

    // 取消
    handleCancel () {
      this.$emit('cancel')
    },

    // 搜索标签
    handleSearchTag (keyword, callback) {
      this.api.getSelectOptionList({ category: 'Tag', keyword }).then(res => {
        this.tagSelectOptionList = res.data.getSelectOptionList
        if (callback && typeof (callback) === 'function') {
          this.$nextTick(() => {
            callback()
          })
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
