<template>
  <div>
    <!-- 表格 -->
    <Table
      :columns="columns"
      :data="list"
      :pageNum="pageNum"
      :pageSize="pageSize"
      v-model="total"
      @pageChange="handlePageChange"
      @pageSizeChange="handlePageSizeChange"
      @tableChange="handleTableChange"
    />
  </div>
</template>

<script>
import Table from '@/components/table/Table'
import page from '@/mixins/page'
import permission from '@/mixins/permission'
import status from '@/mixins/status'
import tool from '@/mixins/tool'

export default {
  name: 'UserLogList',

  components: { Table },

  mixins: [permission, page, status, tool],

  data () {
    return {
      // 表格列
      columns: [
        { title: '用户ID', dataIndex: 'user.id' },
        { title: '用户名', dataIndex: 'user.name' },
        { title: '手机号', dataIndex: 'user.phoneNumber' },
        { title: '品种名', dataIndex: 'stone.title' },
        { title: '访问次数', dataIndex: 'count', sorter: true }
      ],

      // 添加或编辑表单
      addForm: {},
      editForm: {
      },
      form: {}
    }
  },

  props: {
    id: String
  },

  computed: {
    userID () {
      try {
        const res = parseInt(this.id)
        if (Number.isNaN(res)) { this.$router.replace('/') }
        return res
      } catch (e) {
        this.$router.replace('/')
      }
      this.$router.replace('/')
      return 0
    },
    allFilter () {
      return {
        ...this.filter,
        userID: this.userID
      }
    },
    // 获取列表的参数
    args () {
      return {
        data: {
          filter: this.allFilter,
          sort: this.sort
        },
        page: {
          pageNum: this.pageNum,
          pageSize: this.pageSize
        }
      }
    }
  },

  mounted () {
    this.getLogList()
  },

  methods: {
    // 搜索
    handleSearch () {
      this.getLogList()
    },

    // 获取数据
    getLogList () {
      this.api.getUserStoneLogList(this.args).then(res => {
        this.list = res.data.getUserStoneLogList.data
        this.total = res.data.getUserStoneLogList.total
      })
    },

    // 换页回调
    handlePageChange (pageNum, pageSize) {
      this.pageNum = pageNum
      this.pageSize = pageSize
      this.getLogList()
    },

    // 页数回调
    handlePageSizeChange (pageNum, pageSize) {
      this.pageNum = pageNum
      this.pageSize = pageSize
      this.getLogList()
    },

    // 表格回调，筛选、排序等
    handleTableChange (...payload) {
      this.handleFilter(...payload)
      this.handleSort(...payload)
      this.getLogList()
    }
  }
}
</script>

<style scoped>

</style>
