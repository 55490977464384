import moment from 'moment'

// 获取日期
function getDate (text) {
  if (!text) {
    return null
  }
  const t = moment(text)
  if (!t.isValid()) return null
  return t
}

export {
  getDate
}
