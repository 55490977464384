<template>
  <div>
    <!-- 搜索栏 -->
    <a-row :style="{ marginBottom: '16px' }" type="flex" justify="space-between">
      <search-bar :form="searchForm" @search="handleSearch" />
    </a-row>

    <!-- 表格 -->
    <Table
      :columns="columns"
      :data="list"
      :pageNum="pageNum"
      :pageSize="pageSize"
      v-model="total"
      @pageChange="handlePageChange"
      @pageSizeChange="handlePageSizeChange"
      @recordEdit="handleShowModal"
      @recordDel="handleDel"
      @tableChange="handleTableChange"
    />

    <!-- 添加和编辑框 -->
    <order-modal
      ref="modal"
      :title="modalTitle"
      :visible="modalVisible"
      :loading="modalLoading"
      :form="form"
      :isEdit="isEdit"
      @ok="handleAddOrEdit"
      @cancel="handleCloseModal"
    />
  </div>
</template>

<script>
import Table from '@/components/table/Table'
import page from '@/mixins/page'
import permission from '@/mixins/permission'
import status from '@/mixins/status'
import tool from '@/mixins/tool'
import SearchBar from './SearchBar'
import OrderModal from './OrderModal'
import { copy, deepCopy } from '@/utils/object'
import { getDate } from '@/utils/time'

export default {
  name: 'OrderList',

  components: { SearchBar, Table, OrderModal },

  mixins: [permission, page, status, tool],

  data () {
    return {
      // 搜索表单
      searchForm: {
        orderNo: ''
      },

      // 表格列
      columns: [
        { title: '订单号', dataIndex: 'orderNo', width: 150 },
        { title: '收件人', dataIndex: 'username' },
        { title: '联系电话', dataIndex: 'phoneNumber' },
        { title: '收件地址', dataIndex: 'address', customRender: (text, record) => record.region + record.address },
        {
          title: '订单状态',
          dataIndex: 'tradeStatus',
          customRender: text => this.getTradeStatusTag(text),
          filters: [
            { text: '待支付', value: 'Pending' },
            { text: '已支付', value: 'Paid' },
            { text: '已取消', value: 'Canceled' }
          ],
          onFilter: () => true,
          filterMultiple: false
        },
        { title: '支付单号', dataIndex: 'transactionID', width: 150 },
        {
          title: '购买商品',
          dataIndex: 'orderDetail',
          customRender: text => {
            let res = ''
            for (const item of text) {
              res += `${item.title}(${this.stoneSpecCategoryMap[item.spec].text})×${item.count}`
            }
            return res
          }
        },
        { title: '价格', dataIndex: 'totalPrice', sorter: true },
        { title: '支付时间', dataIndex: 'paidAt', customRender: this.handleGetDateTime, sorter: true },
        { title: '使用代币', dataIndex: 'usedToken', sorter: true },
        { title: '实付价格', dataIndex: 'paidPrice', sorter: true },
        { title: '下单时间', dataIndex: 'createdAt', customRender: this.handleGetDateTime, sorter: true }
      ],

      modalVisible: false,
      modalLoading: false,
      addModalTitle: '添加订单',
      editModalTitle: '编辑订单信息',
      isEdit: false,

      // 添加或编辑表单
      addForm: {},
      editForm: {
        id: -1,
        tradeStatus: '',
        orderNo: '',
        transactionID: '',
        paidAt: '',
        totalPrice: 0,
        usedToken: 0,
        paidPrice: 0,
        username: '',
        phoneNumber: '',
        address: '',
        userID: undefined,
        user: {
          name: ''
        }
      },
      form: {}
    }
  },

  computed: {
    modalTitle () {
      return this.isEdit ? this.editModalTitle : this.addModalTitle
    },
    // 获取列表的参数
    args () {
      return {
        order: {
          orderNo: this.searchForm.orderNo,
          filter: this.filter,
          sort: this.sort
        },
        page: {
          pageNum: this.pageNum,
          pageSize: this.pageSize
        }
      }
    }
  },

  mounted () {
    if (this.getPermission('edit') && this.getPermission('delete')) {
      this.columns.push({
        title: '操作',
        dataIndex: 'operation',
        scopedSlots: { customRender: 'operation' }
      })
    }

    this.getOrderList()
  },

  methods: {
    // 搜索
    handleSearch () {
      this.getOrderList()
    },

    // 打开弹框
    handleShowModal (record = undefined) {
      if (record) {
        this.form = deepCopy(this.editForm)
        copy(this.form, record)
        this.form.paidAt = getDate(this.form.paidAt)
        this.isEdit = true
      } else {
        this.form = deepCopy(this.addForm)
        this.isEdit = false
      }
      this.modalVisible = true
    },

    // 关闭弹框
    handleCloseModal () {
      this.modalVisible = false
      // 去掉提示
      this.$refs.modal.$refs.form.resetFields()
    },

    // 添加或编辑
    handleAddOrEdit () {
      this.$refs.modal.$refs.form.validate(valid => {
        if (!valid) return
        if (this.isEdit) {
          this.handleEdit(deepCopy(this.form))
        } else {
          this.handleAdd(deepCopy(this.form))
        }
      })
    },

    // 添加数据
    handleAdd (form) {
      // 无添加订单
    },

    // 编辑数据
    handleEdit (form) {
      delete form.orderNo
      delete form.transactionID
      delete form.usedToken
      delete form.userID
      delete form.user
      form = this.filterNull(form)
      this.api.editOrder(form).then(res => {
        try {
          if (res.data.editOrder.rowsAffected >= 0) {
            this.$message.success('编辑成功')
            this.getOrderList()
            this.handleCloseModal()
            return
          }
          this.$message.error('编辑失败')
        } catch (e) {
          this.$message.error('编辑失败')
        }
      })
    },

    // 删除数据
    handleDel (record) {
      this.api.delOrder(record.id).then(res => {
        try {
          const c = res.data.delOrder.rowsAffected
          if (c !== undefined && c >= 0) {
            this.$message.success('删除成功')
            this.getOrderList()
            return
          }
          this.$message.error('删除失败')
        } catch (e) {
          this.$message.error('删除失败')
        }
      })
    },

    // 获取数据
    getOrderList () {
      this.api.getOrderList(this.args).then(res => {
        this.list = res.data.getOrderList.data
        this.total = res.data.getOrderList.total
      })
    },

    // 换页回调
    handlePageChange (pageNum, pageSize) {
      this.pageNum = pageNum
      this.pageSize = pageSize
      this.getOrderList()
    },

    // 页数回调
    handlePageSizeChange (pageNum, pageSize) {
      this.pageNum = pageNum
      this.pageSize = pageSize
      this.getOrderList()
    },

    // 过滤null参数
    filterNull (obj) {
      const exclude = ['paidAt']
      for (const key in obj) {
        if (exclude.includes(key)) continue
        if (obj[key] === null) obj[key] = ''
      }
      return obj
    },

    // 表格回调，筛选、排序等
    handleTableChange (...payload) {
      this.handleFilter(...payload)
      this.handleSort(...payload)
      this.getOrderList()
    }
  }
}
</script>

<style scoped>

</style>
