import axios from 'axios'

// 将 antd 的 fileList 转为 后端需要的 images
// fileList: [{
//    uid: '-1',
//    name: 'image.png',
//    status: 'done',
//    url: 'https://domain/image.png',
//  }]
// images: [{ url: 'https://domain/image.png', height: 100, width: 100 }]
function toImages (obj) {
  return obj.map(item => ({
    url: item.url,
    height: item.height,
    width: item.width
  }))
}
function toImagesWithPadding (obj) {
  return obj.map(item => ({
    url: item.url,
    height: item.height,
    width: item.width,
    sort: item.sort,
    weight: item.weight
  }))
}
// 单张
function toImage (obj) {
  if (obj.length === 0) {
    return {
      url: '',
      height: 0,
      width: 0
    }
  }
  return {
    url: obj[0].url,
    height: obj[0].height,
    width: obj[0].width
  }
}
function toImageWithPadding (obj) {
  if (obj.length === 0) {
    return {
      url: '',
      height: 0,
      width: 0,
      sort: 0,
      weight: 0
    }
  }
  return {
    url: obj[0].url,
    height: obj[0].height,
    width: obj[0].width,
    sort: obj[0].sort,
    weight: obj[0].weight
  }
}
// 单张
function toImagePack (obj) {
  if (obj.length === 0) return ''
  return obj[0].url
}

// 将后端给的 images 转为 antd 的 fileList
function fromImages (obj) {
  for (const i in obj) {
    obj[i] = {
      uid: i,
      name: obj[i].url,
      status: 'done',
      url: obj[i].url,
      thumbUrl: obj[i].url + '?x-oss-process=image/resize,w_100,m_lfit',
      height: obj[i].height,
      width: obj[i].width
    }
  }
  return obj
}
function fromImagesWithPadding (obj) {
  for (const i in obj) {
    obj[i] = {
      uid: i,
      name: obj[i].url,
      status: 'done',
      url: obj[i].url,
      thumbUrl: obj[i].url + '?x-oss-process=image/resize,w_100,m_lfit',
      height: obj[i].height,
      width: obj[i].width,
      sort: obj[i].sort,
      weight: obj[i].weight
    }
  }
  return obj
}
// 单张
function fromImage (image) {
  if (!image) return []
  if (Array.isArray(image)) return []
  return [{
    uid: 0,
    name: image.url,
    status: 'done',
    url: image.url,
    thumbUrl: image.url + '?x-oss-process=image/resize,w_100,m_lfit',
    height: image.height,
    width: image.width
  }]
}
function fromImageWithPadding (image) {
  if (!image) return []
  if (Array.isArray(image)) return []
  return [{
    uid: 0,
    name: image.url,
    status: 'done',
    url: image.url,
    thumbUrl: image.url + '?x-oss-process=image/resize,w_100,m_lfit',
    height: image.height,
    width: image.width,
    sort: image.sort,
    weight: image.weight
  }]
}
// 单张
function fromImagePack (image) {
  if (!image) return []
  if (Array.isArray(image)) return []
  return [{
    uid: 0,
    name: image.url,
    status: 'done',
    url: image.url,
    thumbUrl: image.url + '?x-oss-process=image/resize,w_100,m_lfit',
    height: 0,
    width: 0
  }]
}

// 返回一个 fileList 的对象
function newImage (length, url, info = {}) {
  return {
    uid: length,
    name: url,
    status: 'done',
    url,
    height: info.height || 0,
    width: info.width || 0,
    sort: 0,
    weight: 0
  }
}
// 返回一个 fileList 的对象
function netImagePack (length, url) {
  return {
    uid: length,
    name: url,
    status: 'done',
    url,
    thumbnail: ''
  }
}

// 根据网络获取图片的 base64
function getImgB64FromURL (file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })
}

// 上传文件到阿里云 OSS
function uploadToOSS (file, ossToken) {
  const key = ossToken.dir + '/' + Date.now() + file.name.substring(file.name.lastIndexOf('.'))
  const formData = new FormData()
  formData.append('key', key)
  formData.append('policy', ossToken.policy)
  formData.append('OSSAccessKeyId', ossToken.accessid)
  formData.append('success_action_status', '200')
  formData.append('callback', '')
  formData.append('signature', ossToken.signature)
  formData.append('file', file)
  formData.append('name', file.name)
  return axios({
    url: ossToken.host,
    method: 'post',
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  }).then(res => {
    if (res.status === 200) {
      return ossToken.host + '/' + key
    }
    return ''
  })
}

export {
  toImages,
  toImagesWithPadding,
  toImage,
  toImageWithPadding,
  fromImages,
  fromImagesWithPadding,
  fromImage,
  fromImageWithPadding,
  newImage,
  getImgB64FromURL,
  uploadToOSS,
  toImagePack,
  fromImagePack,
  netImagePack
}
