import axios from 'axios'
import store from '../store'
import router from '@/router'
import { message } from 'ant-design-vue'

// 不放这里BaseURL不及时生效
store.commit('loadAll')

// axios配置
const config = {
  baseURL: store.state.apiGraphQLBaseURL,
  headers: {
    post: {
      'Content-Type': 'application/json;charset=utf-8'
    }
  }
}

const _axios = axios.create(config)

// 请求拦截器
_axios.interceptors.request.use(config => {
  const token = store.state.token
  token && (config.headers.Authorization = token)
  return config
// eslint-disable-next-line handle-callback-err
}, err => {
  console.error(err)
  const errMsg = '无法连接到服务器'
  message.error(errMsg)
  return Promise.reject(errMsg)
})

// 响应拦截器
_axios.interceptors.response.use((response) => {
  if (response.data.errors) {
    const e = response.data.errors
    let errMsg = '操作失败'
    if (Array.isArray(e) && e.length > 0) errMsg += '：' + e[0].message
    message.error(errMsg)
    return Promise.reject(response)
  } else if (response.data.code === 401) {
    store.commit('clearAll')
    router.push('/login').then()
    return Promise.reject(response)
  }
  // 只返回response中的data数据
  return response.data
}, function (error) {
  if (error.response) {
    error.response.data = error.response.data || {}
    switch (error.response.status) {
      case 400: {
        const errMsg = error.response.data.msg || '参数错误'
        message.error(errMsg)
        return Promise.reject(error.response)
      }
      case 401: {
        store.commit('clearAll')
        router.push('/login').then()
        return Promise.reject(error.response)
      }
      case 403: {
        const errMsg = error.response.data.msg || '无权限'
        message.error(errMsg)
        return Promise.reject(error.response)
      }
      case 404: {
        const errMsg = error.response.data.msg || '页面不存在'
        message.error(errMsg)
        return Promise.reject(error.response)
      }
      case 500: {
        const errMsg = error.response.data.msg || '服务出错，请稍后重试或者联系管理员'
        message.error(errMsg)
        return Promise.reject(error.response)
      }
      case 502: {
        const errMsg = error.response.data.msg || '无法连接到服务器'
        message.error(errMsg)
        return Promise.reject(errMsg)
      }
    }
    const errMsg = '出现未知错误，请稍后重试或者联系管理员'
    message.error(errMsg)
    return Promise.reject(errMsg)
  } else {
    const errMsg = '无法连接到服务器'
    message.error(errMsg)
    return Promise.reject(errMsg)
  }
})

// graphql请求
export const query = (params = {}, noArgs = false) => {
  const payload = {
    operationName: params.operationName,
    query: `query ${params.operationName}($args: ${params.input}) {
      ${params.operationName}(args: $args) {
        ${params.data}
      }
    }`,
    variables: params.variables || {}
  }
  if (noArgs) {
    payload.query = `query ${params.operationName} {
      ${params.operationName} {
        ${params.data}
      }
    }`
  }
  return _axios.post('', payload)
}

export const mutation = (params = {}, isDel = false) => {
  const payload = {
    operationName: params.operationName,
    query: `mutation ${params.operationName}($args: ${params.input}) {
      ${params.operationName}(args: $args) {
        ${params.data}
      }
    }`,
    variables: params.variables || {}
  }
  if (isDel) {
    payload.query = `mutation ${params.operationName} {
      ${params.operationName}(id: ${params.id}) {
        ${params.data}
      }
    }`
  }
  return _axios.post('', payload)
}
