<template>
  <div>
    <!-- 搜索栏 -->
    <a-row :style="{ marginBottom: '16px' }" type="flex" justify="space-between">
      <search-bar :form="searchForm" @search="handleSearch" />
      <a-col v-if="getPermission('add')">
        <a-button @click="handleShowModal(null)">添加</a-button>
      </a-col>
    </a-row>

    <!-- 表格 -->
    <Table
      :columns="columns"
      :data="list"
      :pageNum="pageNum"
      :pageSize="pageSize"
      v-model="total"
      @pageChange="handlePageChange"
      @pageSizeChange="handlePageSizeChange"
      @recordEdit="handleShowModal"
      @recordDel="handleDel"
      @tableChange="handleTableChange"
    />

    <!-- 添加和编辑框 -->
    <stone-modal
      ref="modal"
      :title="modalTitle"
      :visible="modalVisible"
      :loading="modalLoading"
      :form="form"
      :isEdit="isEdit"
      @ok="handleAddOrEdit"
      @cancel="handleCloseModal"
    />
  </div>
</template>

<script>
import Table from '@/components/table/Table'
import page from '@/mixins/page'
import permission from '@/mixins/permission'
import status from '@/mixins/status'
import tool from '@/mixins/tool'
import SearchBar from './SearchBar'
import StoneModal from './StoneModal'
import { copy, deepCopy } from '@/utils/object'
import {
  toImageWithPadding,
  toImagesWithPadding,
  fromImageWithPadding,
  fromImagesWithPadding,
  toImagePack
} from '@/utils/image'

export default {
  name: 'StoneList',

  components: { SearchBar, Table, StoneModal },

  mixins: [permission, page, status, tool],

  data () {
    return {
      // 搜索表单
      searchForm: {
        title: ''
      },

      // 表格列
      columns: [
        { title: 'ID', dataIndex: 'id', sorter: true },
        {
          title: '主图',
          dataIndex: 'image.url',
          align: 'center',
          scopedSlots: { customRender: 'image' }
        },
        { title: '标题', dataIndex: 'title' },
        {
          title: '对应标签',
          dataIndex: 'tags',
          scopedSlots: { customRender: 'tag-list' }
        },
        { title: '样品价格', dataIndex: 'price', customRender: text => '￥' + text, sorter: true },
        { title: '样品库存', dataIndex: 'totalCount', sorter: true },
        { title: '权重', dataIndex: 'weight', sorter: true },
        { title: '总浏览数', dataIndex: 'views', sorter: true },
        { title: '总下载数', dataIndex: 'downCount', sorter: true },
        { title: '添加人', dataIndex: 'manager.name' },
        { title: '添加时间', dataIndex: 'createdAt', customRender: this.handleGetDateTime, sorter: true }
      ],

      modalVisible: false,
      modalLoading: false,
      addModalTitle: '添加石材',
      editModalTitle: '编辑石材信息',
      isEdit: false,

      // 添加或编辑表单
      addForm: {
        title: '',
        content: ' ',
        price: '',
        totalCount: undefined,
        weight: 0,
        image: [],
        images: [],
        tags: [],
        contacts: [],
        imagePack: []
      },
      editForm: {
        id: -1,
        title: '',
        content: '',
        price: '',
        totalCount: undefined,
        weight: 0,
        image: [],
        images: [],
        tags: [],
        contacts: [],
        imagePack: []
      },
      form: {}
    }
  },

  computed: {
    modalTitle () {
      return this.isEdit ? this.editModalTitle : this.addModalTitle
    },
    // 获取列表的参数
    args () {
      return {
        stone: {
          title: this.searchForm.title,
          filter: this.filter,
          sort: this.sort
        },
        page: {
          pageNum: this.pageNum,
          pageSize: this.pageSize
        }
      }
    }
  },

  mounted () {
    if (this.getPermission('edit') && this.getPermission('delete')) {
      this.columns.push({
        title: '操作',
        dataIndex: 'operation',
        scopedSlots: { customRender: 'operation' }
      })
    }

    this.getStoneList()
  },

  methods: {
    // 搜索
    handleSearch () {
      this.getStoneList()
    },

    // 打开弹框
    handleShowModal (record = undefined) {
      if (record) {
        this.form = deepCopy(this.editForm)
        copy(this.form, record)
        this.form.image = fromImageWithPadding(this.form.image)
        this.form.imagePack = fromImageWithPadding(this.form.imagePack)
        this.form.images = fromImagesWithPadding(this.form.images)
        this.isEdit = true
      } else {
        this.form = deepCopy(this.addForm)
        this.isEdit = false
      }
      this.modalVisible = true
    },

    // 关闭弹框
    handleCloseModal () {
      this.modalVisible = false
      // 去掉提示
      this.$refs.modal.$refs.form.resetFields()
    },

    // 添加或编辑
    handleAddOrEdit () {
      if (this.isEdit) {
        this.handleEdit(deepCopy(this.form))
      } else {
        delete this.form.id
        this.handleAdd(deepCopy(this.form))
      }
    },

    // 添加数据
    handleAdd (form) {
      form.contacts = form.contacts.map(item => item.value).filter(item => item !== '')
      form.image = toImageWithPadding(form.image)
      form.imagePack = toImagePack(form.imagePack)
      form.images = toImagesWithPadding(form.images)
      for (const i of form.images.keys()) {
        form.images[i].sort = i
      }
      this.api.addStone(form).then(res => {
        if (res.data.addStone.id) {
          this.$message.success('添加成功')
          this.getStoneList()
          this.handleCloseModal()
        }
      })
    },

    // 编辑数据
    handleEdit (form) {
      form.contacts = form.contacts.map(item => item.value).filter(item => item !== '')
      form.image = toImageWithPadding(form.image)
      form.imagePack = toImagePack(form.imagePack)
      form.images = toImagesWithPadding(form.images)
      for (const i of form.images.keys()) {
        form.images[i].sort = i
      }
      this.api.editStone(form).then(res => {
        try {
          if (res.data.editStone.rowsAffected >= 0) {
            this.$message.success('编辑成功')
            this.getStoneList()
            this.handleCloseModal()
            return
          }
          this.$message.error('编辑失败')
        } catch (e) {
          this.$message.error('编辑失败')
        }
      })
    },

    // 删除数据
    handleDel (record) {
      this.api.delStone(record.id).then(res => {
        try {
          const c = res.data.delStone.rowsAffected
          if (c !== undefined && c >= 0) {
            this.$message.success('删除成功')
            this.getStoneList()
            return
          }
          this.$message.error('删除失败')
        } catch (e) {
          this.$message.error('删除失败')
        }
      })
    },

    // 获取数据
    getStoneList () {
      this.api.getStoneList(this.args).then(res => {
        this.list = res.data.getStoneList.data
        for (const i of this.list.keys()) {
          this.list[i].images = this.list[i].images.sort((a, b) => a.sort - b.sort)
        }
        this.total = res.data.getStoneList.total
      })
    },

    // 换页回调
    handlePageChange (pageNum, pageSize) {
      this.pageNum = pageNum
      this.pageSize = pageSize
      this.getStoneList()
    },

    // 页数回调
    handlePageSizeChange (pageNum, pageSize) {
      this.pageNum = pageNum
      this.pageSize = pageSize
      this.getStoneList()
    },

    // 表格回调，筛选、排序等
    handleTableChange (...payload) {
      this.handleFilter(...payload)
      this.handleSort(...payload)
      this.getStoneList()
    }
  }
}
</script>

<style scoped>

</style>
