<template>
  <div>
    <a-form-model layout="inline" ref="form" :model="form">
      <a-form-model-item prop="tagID" label="相关标签">
        <a-select v-model="form.tagID" style="width: 203px" placeholder="石材相关标签，打字可搜索" mode="default" :not-found-content="null"
                  show-search :filter-option="false" :show-arrow="false" @search="handleSearchTag" allowClear>
          <a-select-option v-for="(item, index) of tagSelectOptionList" :value="item.id" :key="index">
            {{item.name}}
          </a-select-option>
        </a-select>
      </a-form-model-item>

      <a-form-model-item>
        <a-button type="primary" @click="handleSearch">
          搜索
        </a-button>
      </a-form-model-item>
      <a-form-model-item>
        <a-button @click="handleResetSearchForm">
          重置
        </a-button>
      </a-form-model-item>
    </a-form-model>
  </div>
</template>

<script>
export default {
  name: 'SearchBar',

  props: {
    form: { type: Object, required: true }
  },

  data () {
    return {
      // 用于Select的标签选项列表
      tagSelectOptionList: []
    }
  },

  beforeMount () {
    this.handleSearchTag('')
  },

  methods: {
    // 搜索
    handleSearch () {
      this.$emit('search')
    },

    // 重置搜索
    handleResetSearchForm () {
      this.$refs.form.resetFields()
      this.handleSearch()
    },

    // 搜索标签
    handleSearchTag (keyword, callback) {
      this.api.getSelectOptionList({ category: 'Tag', keyword }).then(res => {
        this.tagSelectOptionList = res.data.getSelectOptionList
        if (callback && typeof (callback) === 'function') {
          this.$nextTick(() => {
            callback()
          })
        }
      })
    }
  }
}
</script>

<style scoped>
</style>
