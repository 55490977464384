// 混入表格页面通用的属性
export default {
  data () {
    return {
      // 表格数据
      list: [],

      // 分页相关
      pageNum: 1,
      pageSize: 10,
      total: 0,

      // 筛选相关
      filter: {},

      // 排序相关
      sort: {},
      sortMap: {
        ascend: 'ASC',
        descend: 'DESC'
      }
    }
  },

  methods: {
    // 筛选
    handleFilter (pagination, filters, sorter, { currentDataSource }) {
      if (!this._.isEmpty(filters)) {
        for (const key in filters) {
          if (key === 'filterCategory') {
            this.filter = {
              [key]: filters[key]
            }
            continue
          }
          this.filter = {
            [key]: filters[key][0]
          }
        }
      }
    },

    // 排序
    handleSort (pagination, filters, sorter, { currentDataSource }) {
      if (!this._.isEmpty(sorter)) {
        this.sort = {
          [sorter.field]: this.sortMap[sorter.order]
        }
      }
    }
  }
}
