<template>
  <div>
    <!-- 搜索栏 -->
    <a-row :style="{ marginBottom: '16px' }" type="flex" justify="space-between">
      <search-bar :form="searchForm" @search="handleSearch" />
    </a-row>

    <!-- 表格 -->
    <Table
      :columns="columns"
      :data="list"
      :pageNum="pageNum"
      :pageSize="pageSize"
      v-model="total"
      @pageChange="handlePageChange"
      @pageSizeChange="handlePageSizeChange"
      @recordEdit="handleShowModal"
      @changeWeight="handleShowChangeWeightModal"
      @tableChange="handleTableChange"
    />

    <!-- 添加和编辑框 -->
    <image-modal
      ref="modal"
      :title="modalTitle"
      :visible="modalVisible"
      :loading="modalLoading"
      :form="form"
      @ok="handleAddOrEdit"
      @cancel="handleCloseModal"
    />

    <!-- 修改权重 -->
    <image-change-weight-modal
      :visible="weightModalVisible"
      :form="weightForm"
      @ok="handleChangeWeight"
      @cancel="weightModalVisible = false"
    />
  </div>
</template>

<script>
import Table from '@/components/table/Table'
import page from '@/mixins/page'
import permission from '@/mixins/permission'
import status from '@/mixins/status'
import tool from '@/mixins/tool'
import SearchBar from './SearchBar'
import ImageModal from './ImageModal'
import ImageChangeWeightModal from './ImageChangeWeightModal'
import { copy, deepCopy } from '@/utils/object'

export default {
  name: 'ImageList',

  components: { SearchBar, Table, ImageModal, ImageChangeWeightModal },

  mixins: [permission, page, status, tool],

  data () {
    return {
      // 搜索表单
      searchForm: {
        tagID: undefined
      },

      // 表格列
      columns: [
        { title: '图片', dataIndex: 'url', align: 'center', scopedSlots: { customRender: 'image' } },
        { title: '石材标题', dataIndex: 'stone.title' },
        { title: '权重', dataIndex: 'weight', sorter: true },
        {
          title: '对应标签',
          dataIndex: 'tags',
          scopedSlots: { customRender: 'tag-list' }
        },
        { title: '添加时间', dataIndex: 'createdAt', customRender: this.handleGetDateTime, sorter: true }
      ],

      modalVisible: false,
      modalLoading: false,
      modalTitle: '编辑图片信息',

      // 添加或编辑表单
      editForm: {
        id: -1,
        tags: []
      },
      form: {},

      weightModalVisible: false,
      weightForm: {}
    }
  },

  computed: {
    // 获取列表的参数
    args () {
      return {
        image: {
          tagID: this.searchForm.tagID,
          filter: this.filter,
          sort: this.sort
        },
        page: {
          pageNum: this.pageNum,
          pageSize: this.pageSize
        }
      }
    }
  },

  mounted () {
    if (this.getPermission('edit')) {
      this.columns.push({
        title: '操作',
        dataIndex: 'operation',
        scopedSlots: { customRender: 'operation.weight' },
        width: 200
      })
    }

    this.getImageList()
  },

  methods: {
    // 搜索
    handleSearch () {
      // if (!this.searchForm.tagID) return
      this.getImageList()
    },

    // 打开弹框
    handleShowModal (record = undefined) {
      if (record) {
        this.form = deepCopy(this.editForm)
        copy(this.form, record)
        this.isEdit = true
      }
      this.modalVisible = true
    },

    // 关闭弹框
    handleCloseModal () {
      this.modalVisible = false
      // 去掉提示
      this.$refs.modal.$refs.form.resetFields()
    },

    // 添加或编辑
    handleAddOrEdit () {
      this.$refs.modal.$refs.form.validate(valid => {
        if (!valid) return
        this.handleEdit(deepCopy(this.form))
      })
    },

    // 编辑数据
    handleEdit (form) {
      this.api.editImage(form).then(res => {
        try {
          if (res.data.editImage.rowsAffected >= 0) {
            this.$message.success('编辑成功')
            this.getImageList()
            this.handleCloseModal()
            return
          }
          this.$message.error('编辑失败')
        } catch (e) {
          this.$message.error('编辑失败')
        }
      })
    },

    // 获取数据
    getImageList () {
      this.api.getImageList(this.args).then(res => {
        this.list = res.data.getImageList.data
        this.total = res.data.getImageList.total
      })
    },

    // 换页回调
    handlePageChange (pageNum, pageSize) {
      this.pageNum = pageNum
      this.pageSize = pageSize
      this.getImageList()
    },

    // 页数回调
    handlePageSizeChange (pageNum, pageSize) {
      this.pageNum = pageNum
      this.pageSize = pageSize
      this.getImageList()
    },

    // 表格回调，筛选、排序等
    handleTableChange (...payload) {
      this.handleFilter(...payload)
      this.handleSort(...payload)
      this.getImageList()
    },

    // 修改权重
    handleShowChangeWeightModal (record) {
      this.weightForm = {
        id: record.id,
        weight: record.weight
      }
      this.weightModalVisible = true
    },
    handleChangeWeight (form) {
      this.api.editImageWeight(form).then(res => {
        try {
          if (res.data.editImageWeight.rowsAffected >= 0) {
            this.$message.success('编辑成功')
            this.getImageList()
            this.weightModalVisible = false
            return
          }
          this.$message.error('编辑失败')
        } catch (e) {
          this.$message.error('编辑失败')
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
