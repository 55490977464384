<template>
  <div>
    <a-modal
      title="修改权重"
      :visible="visible"
      @ok="handleOk"
      @cancel="handleCancel"
      :centered="true"
      :maskClosable="false"
      :width="300"
    >
      <a-form-model
        :rules="rules"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
        ref="form"
        :model="form"
      >

        <a-input-number prop="weight" placeholder="图片搜索权重，数值越高权重越大" style="width: 100%"
                        v-model="form.weight" :min="0" :max="999999999" />

      </a-form-model>
    </a-modal>
  </div>
</template>

<script>

import { deepCopy } from '@/utils/object'

export default {
  name: 'ImageChangeWeightModal',

  data () {
    return {
      labelCol: { span: 5 },
      wrapperCol: { span: 16 },
      // 表单验证规则
      rules: {
        weight: [
          { required: true, message: '请输入图片搜索权重', trigger: ['blur', 'change'] }
        ]
      }
    }
  },

  props: {
    visible: { type: Boolean, default: false },
    form: { type: Object, required: true }
  },

  methods: {
    // 确认
    handleOk () {
      this.$refs.form.validate(valid => {
        if (!valid) return
        this.$emit('ok', deepCopy(this.form))
      })
    },

    // 取消
    handleCancel () {
      this.$emit('cancel')
    }
  }
}
</script>

<style scoped>

</style>
