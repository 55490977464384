<template>
  <div>
    <div class="file-list">
      <!-- 上传加载 -->
      <a-spin :spinning="isUpload">
        <!-- 上传组件 -->
        <a-upload
          list-type="picture"
          name="file"
          :multiple="false"
          :file-list="[]"
          :custom-request="handleUploadImage"
          :before-upload="handleBeforeUpload"
          :disabled="isUpload"
        >
          <a-button icon="upload">{{ buttonTitle }}</a-button>
        </a-upload>

        <!-- 拖拽组件 -->
        <div class="ant-upload-list ant-upload-list-picture">
          <draggable :list="form[field]">
            <transition-group type="transition" :css="true">
              <div class="ant-upload-list-item ant-upload-list-item-done"
                   v-for="(item, index) in form[field]" :key="index">
                <div class="ant-upload-list-item-info">
                  <img class="ant-upload-list-item-thumbnail"
                       :src="item.url + '?x-oss-process=image/resize,w_300,m_lfit'"
                       @click="handlePreview(item)"
                       style="cursor: pointer;"
                  />
                  <span class="ant-upload-list-item-name">
                    <a-input-number placeholder="图片搜索权重，数值越高权重越大" style="width: 100%"
                                    :defaultValue="item.weight" :min="0" :max="999999999" @change="(v) => handleChangeWeight(index, v)" />
                  </span>
                </div>

                <!-- 删除按钮 -->
                <a-icon type="close-circle" class="close-btn" @click="handleRemove(index)" />
              </div>
            </transition-group>
          </draggable>
        </div>
      </a-spin>
    </div>

    <!-- 预览图片 -->
    <a-modal :visible="previewVisible" :centered="true" :footer="null" @cancel="() => this.previewVisible = false">
      <img alt="图片预览" style="width: 100%" :src="previewImage" />
    </a-modal>
  </div>
</template>

<script>
import Draggable from 'vuedraggable'
import { getImgB64FromURL, uploadToOSS } from '@/utils/image'
import { getOSSToken } from '@/utils/oss'

export default {
  name: 'DraggableUpload',

  components: { Draggable },

  data () {
    return {
      // 图片预览 modal
      previewVisible: false,
      // 预览的图片地址
      previewImage: '',

      // 是否上传
      isUpload: false
    }
  },

  props: {
    form: { type: Object, required: true },
    field: { type: String, required: true },
    limit: { type: Number, default: -1 },
    buttonTitle: { type: String, default: '上传' }
  },

  methods: {
    // 预览图片
    async handlePreview (file) {
      if (!file.url && !file.preview) {
        file.preview = await getImgB64FromURL(file.originFileObj)
      }
      this.previewImage = file.url + '?x-oss-process=image/resize,w_500,m_lfit' || file.preview
      this.previewVisible = true
    },

    // 自定义上传图片逻辑
    handleUploadImage ({ file }) {
      // 显示进度条
      this.isUpload = true

      getOSSToken().then(ossToken => {
        if (ossToken) {
          uploadToOSS(file, ossToken)
            .then(url => {
              if (url) {
                this.isUpload = false
                // 获取图片信息，返回宽和高
                this.getImageInfo(file, (info) => {
                  this.form[this.field].push({
                    url,
                    height: info.height || 0,
                    width: info.width || 0,
                    sort: this.form[this.field].length,
                    weight: 0
                  })
                })
              } else {
                this.$message.error('上传失败')
              }
            })
            .catch(() => {
              this.isUpload = false
              this.$message.error('上传失败')
            })
        } else {
          this.isUpload = false
          this.$message.error('上传失败')
        }
      }).catch(() => {
        this.isUpload = false
        this.$message.error('上传失败')
      })
    },

    // 自定义删除图片逻辑
    handleRemove (index) {
      this.form[this.field].splice(index, 1)
    },

    // 上传前拦截
    handleBeforeUpload (file) {
      const isSuffixValid = /^image\/jp[e]+g|image\/png|image\/gif$/.test(file.type)
      if (!isSuffixValid) {
        this.$message.error('只能上传图片')
      }
      // const isLt2M = file.size / 1024 / 1024 < 2
      // if (!isLt2M) {
      //   this.$message.error('Image must smaller than 2MB!')
      // }
      return isSuffixValid
    },

    // 获取图片宽高
    getImageInfo (file, callback) {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = function () {
        const img = new Image()
        img.src = reader.result
        if (img.complete) {
          // eslint-disable-next-line standard/no-callback-literal
          callback({ height: img.height, width: img.width })
          return
        }
        img.onload = () => {
          // eslint-disable-next-line standard/no-callback-literal
          callback({ height: img.height, width: img.width })
        }
      }
    },

    handleChangeWeight (i, v) {
      this.form[this.field][i].weight = v
    }
  }
}
</script>

<style scoped>
.close-btn {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 20px;
  cursor: pointer;
}
.ant-upload-list-picture .ant-upload-list-item-name, .ant-upload-list-picture-card .ant-upload-list-item-name {
  padding-right: 50px;
}
</style>
