import moment from 'moment'

export default {
  methods: {
    // 获取日期
    handleGetDate (text) {
      if (!text) {
        return ''
      }
      const r = moment(text).format('YYYY-MM-DD')
      if (r === 'Invalid date') return ''
      return r
    },

    // 获取时间
    handleGetDateTime (text) {
      if (!text) {
        return ''
      }
      const r = moment(text).format('YYYY-MM-DD HH:mm:ss')
      if (r === 'Invalid date') return ''
      return r
    }
  }
}
