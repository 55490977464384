<template>
  <div>
    <!-- 搜索栏 -->
    <a-row :style="{ marginBottom: '16px' }" type="flex" justify="space-between">
      <search-bar :form="searchForm" @search="handleSearch" />
      <a-col v-if="getPermission('add')">
        <a-button @click="handleShowModal(null)">添加用户</a-button>
      </a-col>
    </a-row>

    <!-- 表格 -->
    <Table
      :columns="columns"
      :data="list"
      :pageNum="pageNum"
      :pageSize="pageSize"
      v-model="total"
      @pageChange="handlePageChange"
      @pageSizeChange="handlePageSizeChange"
      @recordEdit="handleShowModal"
      @recordDel="handleDel"
      @tableChange="handleTableChange"
    />

    <!-- 添加和编辑框 -->
    <user-modal
      ref="modal"
      :title="modalTitle"
      :visible="modalVisible"
      :loading="modalLoading"
      :form="form"
      :isEdit="isEdit"
      @ok="handleAddOrEdit"
      @cancel="handleCloseModal"
    />
  </div>
</template>

<script>
import Table from '@/components/table/Table'
import page from '@/mixins/page'
import permission from '@/mixins/permission'
import status from '@/mixins/status'
import tool from '@/mixins/tool'
import SearchBar from './SearchBar'
import UserModal from './UserModal'
import { copy, deepCopy } from '@/utils/object'

export default {
  name: 'UserList',

  components: { SearchBar, Table, UserModal },

  mixins: [permission, page, status, tool],

  data () {
    return {
      // 搜索表单
      searchForm: {},

      // 表格列
      columns: [
        { title: 'ID', dataIndex: 'id' },
        { title: '公司名', dataIndex: 'companyName' },
        { title: '姓名', dataIndex: 'name', scopedSlots: { customRender: 'user-log' } },
        { title: '手机号', dataIndex: 'phoneNumber', scopedSlots: { customRender: 'user-log' } },
        { title: '邮箱', dataIndex: 'email' },
        {
          title: '性别',
          dataIndex: 'gender',
          customRender: text => this.getGenderTag(text),
          filters: [
            { text: '男', value: 'M' },
            { text: '女', value: 'F' },
            { text: '保密', value: 'U' }
          ],
          onFilter: () => true,
          filterMultiple: false
        },
        { title: '可用代币', dataIndex: 'token', sorter: true },
        { title: '添加时间', dataIndex: 'createdAt', customRender: this.handleGetDateTime, sorter: true }
      ],

      modalVisible: false,
      modalLoading: false,
      addModalTitle: '添加用户',
      editModalTitle: '编辑用户信息',
      isEdit: false,

      // 添加或编辑表单
      addForm: {
        companyName: '',
        name: '',
        gender: 'U',
        phoneNumber: '',
        email: '',
        password: '',
        token: 0
      },
      editForm: {
        id: -1,
        companyName: '',
        name: '',
        gender: 'U',
        phoneNumber: '',
        email: '',
        password: '',
        token: 0
      },
      form: {}
    }
  },

  computed: {
    modalTitle () {
      return this.isEdit ? this.editModalTitle : this.addModalTitle
    },
    allFilter () {
      return {
        ...this.filter,
        ...this.searchForm
      }
    },
    // 获取列表的参数
    args () {
      return {
        data: {
          filter: this.allFilter,
          sort: this.sort
        },
        page: {
          pageNum: this.pageNum,
          pageSize: this.pageSize
        }
      }
    }
  },

  mounted () {
    if (this.getPermission('edit') && this.getPermission('delete')) {
      this.columns.push({
        title: '操作',
        dataIndex: 'operation',
        scopedSlots: { customRender: 'operation' }
      })
    }

    this.getUserList()
  },

  methods: {
    // 搜索
    handleSearch () {
      this.getUserList()
    },

    // 打开弹框
    handleShowModal (record = undefined) {
      if (record) {
        this.form = deepCopy(this.editForm)
        copy(this.form, record)
        this.isEdit = true
      } else {
        this.form = deepCopy(this.addForm)
        this.isEdit = false
      }
      this.modalVisible = true
    },

    // 关闭弹框
    handleCloseModal () {
      this.modalVisible = false
      // 去掉提示
      this.$refs.modal.$refs.form.resetFields()
    },

    // 添加或编辑
    handleAddOrEdit () {
      this.$refs.modal.$refs.form.validate(valid => {
        if (!valid) return
        if (this.isEdit) {
          this.handleEdit(deepCopy(this.form))
        } else {
          this.handleAdd(deepCopy(this.form))
        }
      })
    },

    // 添加数据
    handleAdd (form) {
      this.api.addUser(form).then(res => {
        if (res.data.addUser.id) {
          this.$message.success('添加成功')
          this.getUserList()
          this.handleCloseModal()
        }
      })
    },

    // 编辑数据
    handleEdit (form) {
      this.api.editUser(form).then(res => {
        try {
          if (res.data.editUser.rowsAffected >= 0) {
            this.$message.success('编辑成功')
            this.getUserList()
            this.handleCloseModal()
            return
          }
          this.$message.error('编辑失败')
        } catch (e) {
          this.$message.error('编辑失败')
        }
      })
    },

    // 删除数据
    handleDel (record) {
      this.api.delUser(record.id).then(res => {
        try {
          const c = res.data.delUser.rowsAffected
          if (c !== undefined && c >= 0) {
            this.$message.success('删除成功')
            this.getUserList()
            return
          }
          this.$message.error('删除失败')
        } catch (e) {
          this.$message.error('删除失败')
        }
      })
    },
    // 获取用户数据
    getUserList () {
      this.api.getUserList(this.args).then(res => {
        this.list = res.data.getUserList.data
        this.total = res.data.getUserList.total
      })
    },

    // 换页回调
    handlePageChange (pageNum, pageSize) {
      this.pageNum = pageNum
      this.pageSize = pageSize
      this.getUserList()
    },

    // 页数回调
    handlePageSizeChange (pageNum, pageSize) {
      this.pageNum = pageNum
      this.pageSize = pageSize
      this.getUserList()
    },

    // 表格回调，筛选、排序等
    handleTableChange (...payload) {
      this.handleFilter(...payload)
      this.handleSort(...payload)
      this.getUserList()
    }
  }
}
</script>

<style scoped>

</style>
