// 混入权限，所有页面均需混入
export default {
  data () {
    return {
      permissionMap: this.$store.state.permissionMap
    }
  },

  methods: {
    // 根据CRUD判断是否有权限
    getPermission (action) {
      return this.permissionMap[this.$route.path][action] || false
    }
  }
}
