<template>
  <div>
    <!-- 搜索栏 -->
    <a-row :style="{ marginBottom: '16px' }" type="flex" justify="space-between">
      <search-bar :form="searchForm" @search="handleSearch" />
      <a-col v-if="getPermission('add')">
        <a-button @click="handleShowModal(null)">添加</a-button>
      </a-col>
    </a-row>

    <!-- 表格 -->
    <Table
      :columns="columns"
      :data="list"
      :pageNum="pageNum"
      :pageSize="pageSize"
      v-model="total"
      @pageChange="handlePageChange"
      @pageSizeChange="handlePageSizeChange"
      @recordEdit="handleShowModal"
      @recordDel="handleDel"
      @tableChange="handleTableChange"
    />

    <!-- 添加和编辑框 -->
    <tag-modal
      ref="modal"
      :title="modalTitle"
      :visible="modalVisible"
      :loading="modalLoading"
      :form="form"
      :isEdit="isEdit"
      @ok="handleAddOrEdit"
      @cancel="handleCloseModal"
    />
  </div>
</template>

<script>
import Table from '@/components/table/Table'
import page from '@/mixins/page'
import permission from '@/mixins/permission'
import status from '@/mixins/status'
import tool from '@/mixins/tool'
import SearchBar from './SearchBar'
import TagModal from './TagModal'
import { copy, deepCopy } from '@/utils/object'

export default {
  name: 'TagList',

  components: { SearchBar, Table, TagModal },

  mixins: [permission, page, status, tool],

  data () {
    return {
      // 搜索表单
      searchForm: {
        name: ''
      },

      // 表格列
      columns: [
        { title: '标签名', dataIndex: 'name', align: 'center' },
        {
          title: '筛选类型',
          scopedSlots: { customRender: 'filter-category' }
        },
        { title: '添加人', dataIndex: 'manager.name', align: 'center' },
        { title: '添加时间', dataIndex: 'createdAt', align: 'center', customRender: this.handleGetDateTime, sorter: true }
      ],

      modalVisible: false,
      modalLoading: false,
      addModalTitle: '添加标签',
      editModalTitle: '编辑标签信息',
      isEdit: false,

      // 添加或编辑表单
      addForm: {
        name: '',
        isColor: false,
        isMaterial: false,
        isTexture: false
      },
      editForm: {
        id: -1,
        name: '',
        isColor: false,
        isMaterial: false,
        isTexture: false
      },
      form: {}
    }
  },

  computed: {
    modalTitle () {
      return this.isEdit ? this.editModalTitle : this.addModalTitle
    },
    // 获取列表的参数
    args () {
      return {
        tag: {
          name: this.searchForm.name,
          filter: this.filter,
          sort: this.sort
        },
        page: {
          pageNum: this.pageNum,
          pageSize: this.pageSize
        }
      }
    }
  },

  mounted () {
    if (this.getPermission('edit') && this.getPermission('delete')) {
      this.columns.push({
        title: '操作',
        dataIndex: 'operation',
        scopedSlots: { customRender: 'operation' }
      })
    }

    this.getTagList()
  },

  methods: {
    // 搜索
    handleSearch () {
      this.getTagList()
    },

    // 打开弹框
    handleShowModal (record = undefined) {
      if (record) {
        this.form = deepCopy(this.editForm)
        copy(this.form, record)
        this.isEdit = true
      } else {
        this.form = deepCopy(this.addForm)
        this.isEdit = false
      }
      this.modalVisible = true
    },

    // 关闭弹框
    handleCloseModal () {
      this.modalVisible = false
      // 去掉提示
      this.$refs.modal.$refs.form.resetFields()
    },

    // 添加或编辑
    handleAddOrEdit () {
      this.$refs.modal.$refs.form.validate(valid => {
        if (!valid) return
        if (this.isEdit) {
          this.handleEdit(deepCopy(this.form))
        } else {
          delete this.form.id
          this.handleAdd(deepCopy(this.form))
        }
      })
    },

    // 添加数据
    handleAdd (form) {
      this.api.addTag(form).then(res => {
        if (res.data.addTag.id) {
          this.$message.success('添加成功')
          this.getTagList()
          this.handleCloseModal()
        }
      })
    },

    // 编辑数据
    handleEdit (form) {
      this.api.editTag(form).then(res => {
        try {
          if (res.data.editTag.rowsAffected >= 0) {
            this.$message.success('编辑成功')
            this.getTagList()
            this.handleCloseModal()
            return
          }
          this.$message.error('编辑失败')
        } catch (e) {
          this.$message.error('编辑失败')
        }
      })
    },

    // 删除数据
    handleDel (record) {
      this.api.delTag(record.id).then(res => {
        try {
          const c = res.data.delTag.rowsAffected
          if (c !== undefined && c >= 0) {
            this.$message.success('删除成功')
            this.getTagList()
            return
          }
          this.$message.error('删除失败')
        } catch (e) {
          this.$message.error('删除失败')
        }
      })
    },

    // 获取数据
    getTagList () {
      this.api.getTagList(this.args).then(res => {
        this.list = res.data.getTagList.data
        this.total = res.data.getTagList.total
      })
    },

    // 换页回调
    handlePageChange (pageNum, pageSize) {
      this.pageNum = pageNum
      this.pageSize = pageSize
      this.getTagList()
    },

    // 页数回调
    handlePageSizeChange (pageNum, pageSize) {
      this.pageNum = pageNum
      this.pageSize = pageSize
      this.getTagList()
    },

    // 表格回调，筛选、排序等
    handleTableChange (...payload) {
      this.handleFilter(...payload)
      this.handleSort(...payload)
      this.getTagList()
    }
  }
}
</script>

<style scoped>

</style>
