// 获取菜单和权限
function parsePermissionList (permissionList = []) {
  // 处理菜单栏
  const menuMap = {
    '/': { label: '仪表盘', path: '/', icon: 'dashboard', sort: 1 },
    '/user-list': { label: '用户管理', path: '/user-list', icon: 'user', sort: 2 },
    '/manager-list': { label: '管理人员', path: '/manager-list', icon: 'team', sort: 5 },
    '/tag-list': { label: '标签管理', path: '/tag-list', icon: 'tags', sort: 3 },
    '/token-list': { label: '代币管理', path: '/token-list', icon: 'pay-circle', sort: 6 },
    '/stone-list': { label: '石材管理', path: '/stone-list', icon: 'file-image', sort: 4 },
    '/order-list': { label: '订单管理', path: '/order-list', icon: 'profile', sort: 7 },
    '/image-list': { label: '图片管理', path: '/image-list', icon: 'picture', sort: 8 },
    '/log-list': { label: '浏览记录', path: '/log-list', icon: 'profile', sort: 8 },
    '/user-log-list': { label: '用户浏览记录', path: '/user-log-list', icon: 'profile', sort: 9, hide: true }
  }
  // 默认将根目录压入
  let menuList = [menuMap['/']]
  // 处理权限，具体到CRUD，给页面中使用
  const permissionMap = {}

  // 先提取一次第一级的
  for (const v of permissionList) {
    if (menuMap[v]) {
      menuList.push(menuMap[v])
      permissionMap[v] = {}
    }
  }

  // 再提取第二级CURD，并补全缺少的第一级
  for (const v of permissionList) {
    if (v.includes('.')) {
      const t = v.split('.')
      if (t.length !== 2) continue
      // 如果不存在则添加，因为tree组件需要全选子节点父节点才会被选中
      if (!permissionMap[t[0]] && menuMap[t[0]]) {
        menuList.push(menuMap[t[0]])
        permissionMap[t[0]] = {}
      }
      if (permissionMap[t[0]]) {
        permissionMap[t[0]][t[1]] = true
      }
    }
  }
  // 排序一下
  menuList = menuList.sort((a, b) => a.sort - b.sort)
  return { menuList, permissionMap }
}

export {
  parsePermissionList
}
