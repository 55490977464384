// 根据指定的key去重对象数组
function removeDuplication (target, key) {
  const map = {}
  return target.reduce((cur, next) => {
    if (!map[next[key]]) {
      map[next[key]] = true
      cur.push(next)
    }
    return cur
  }, [])
}

export {
  removeDuplication
}
