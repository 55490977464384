import _ from 'lodash'

// 并且保持target对象的字段，确保会新增字段
function copy (target, src) {
  const tmpSrc = deepCopy(src)
  for (const key in target) {
    if (Object.hasOwnProperty.call(tmpSrc, key)) {
      target[key] = tmpSrc[key]
    }
  }
}

// 深拷贝后返回一个新的对象
function deepCopy (src) {
  return _.cloneDeep(src)
}

export {
  copy,
  deepCopy
}
