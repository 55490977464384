<template>
  <div>
    <a-modal
      :title="title"
      :visible="visible"
      :confirm-loading="loading"
      @ok="handleOk"
      @cancel="handleCancel"
      :centered="true"
      :maskClosable="false"
      :width="800"
    >
      <a-form-model
        :rules="rules"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
        ref="form"
        :model="form"
      >
        <a-form-model-item prop="name" label="名称">
          <a-input placeholder="名称" v-model="form.name" />
        </a-form-model-item>

        <a-form-model-item prop="isColor" label="是否色系">
          <a-switch v-model="form.isColor" />
        </a-form-model-item>

        <a-form-model-item prop="isMaterial" label="是否材质">
          <a-switch v-model="form.isMaterial" />
        </a-form-model-item>

        <a-form-model-item prop="isTexture" label="是否纹理">
          <a-switch v-model="form.isTexture" />
        </a-form-model-item>

      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import status from '@/mixins/status'
import permission from '@/mixins/permission'

export default {
  name: 'TagModal',

  data () {
    return {
      labelCol: { span: 5 },
      wrapperCol: { span: 16 },
      // 表单验证规则
      rules: {
        name: [
          { required: true, message: '请输入名称', trigger: ['blur', 'change'] },
          { min: 1, max: 20, message: '名称长度为1-20', trigger: ['blur', 'change'] }
        ],
        isColor: [
          { required: true, message: '请选择色系', trigger: ['blur', 'change'] }
        ],
        isMaterial: [
          { required: true, message: '请选择材质', trigger: ['blur', 'change'] }
        ],
        isTexture: [
          { required: true, message: '请选择纹理', trigger: ['blur', 'change'] }
        ]
      }
    }
  },

  mixins: [permission, status],

  props: {
    title: String,
    visible: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
    form: { type: Object, required: true },
    isEdit: { type: Boolean, default: false }
  },

  methods: {
    // 确认
    handleOk () {
      this.$emit('ok')
    },

    // 取消
    handleCancel () {
      this.$emit('cancel')
    }
  }
}
</script>

<style scoped>

</style>
