<template>
  <a-layout style="min-height: 100vh">
    <!-- 侧边栏 -->
    <a-layout-sider class="side-bar" theme="light" v-model="collapsed" width="200">
      <div class="brand">
        <div class="logo">
          <img alt="logo" src="@/assets/images/logo.png" />
          <h1 v-if="!collapsed">石上良品</h1>
        </div>
      </div>

      <div class="menu-container">
        <a-menu theme="light" :selected-keys="[currentRoutePath]" mode="inline">
          <a-menu-item v-for="item in showMenuList" :key="item.path">
            <router-link :to="item.path">
              <a-icon :type="item.icon" />
              <span>{{item.label}}</span>
            </router-link>
          </a-menu-item>
        </a-menu>
      </div>
    </a-layout-sider>

    <!-- 主体 -->
    <a-layout style="min-width: 1200px; overflow-x: auto">
      <!-- 头部 -->
      <a-layout-header class="top-bar" style="background: #fff; padding: 0; height: 72px; line-height: 72px">
        <a-row type="flex" justify="space-between">
          <a-col>
            <a-icon
              class="trigger"
              :type="collapsed ? 'menu-unfold' : 'menu-fold'"
              @click="() => (this.collapsed = !this.collapsed)"
            />
          </a-col>

          <a-col>
            <a-menu mode="horizontal" :style="{ height: '72px', lineHeight: '72px', borderBottom: 'none' }">
              <a-sub-menu>
                <span slot="title"><a-icon type="setting" />你好，{{ $store.state.managerInfo.name || '管理员' }}</span>
                <a-menu-item key="logout" @click="handleLogout">
                  退出登录
                </a-menu-item>
              </a-sub-menu>
            </a-menu>
          </a-col>
        </a-row>
      </a-layout-header>

      <a-layout-content style="margin: 0 16px">
        <!-- 面包屑 -->
        <a-breadcrumb style="margin: 16px 0">
          <a-breadcrumb-item>首页</a-breadcrumb-item>
          <a-breadcrumb-item>{{ $route.meta.title }}</a-breadcrumb-item>
        </a-breadcrumb>

        <!-- 主体 -->
        <router-view v-if="isRouterAlive && $route.path === '/'" :style="{ padding: '24px' }"></router-view>
        <router-view v-if="isRouterAlive && $route.path !== '/'" :style="{ padding: '24px', background: '#fff', minHeight: '360px' }"></router-view>
      </a-layout-content>

      <a-layout-footer style="text-align: center">
        石上良品 ©2022
      </a-layout-footer>
    </a-layout>
  </a-layout>
</template>
<script>
export default {
  // TODO 后退页面菜单栏不联动更新选中
  // https://segmentfault.com/q/1010000038219480
  name: 'Home',

  data () {
    return {
      collapsed: false,
      isRouterAlive: true,
      menuList: this.$store.state.menuList
    }
  },

  computed: {
    currentRoutePath () {
      return this.$route.path
    },
    showMenuList () {
      const list = []
      for (const item of this.menuList) {
        if (item.hide) continue
        list.push(item)
      }
      return list
    }
  },

  methods: {
    // View刷新重载
    routerViewReload () {
      this.isRouterAlive = false
      this.$nextTick(() => {
        this.isRouterAlive = true
      })
    },

    // 退出登录
    handleLogout () {
      this.$store.commit('clearAll')
      this.$router.replace('/login')
    }
  }
}
</script>

<style>
.side-bar {
  box-shadow: rgba(85,170,221,0.1) 0 0 28px 0;
  z-index: 10;
}
.side-bar :global .ant-layout-sider-children {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.brand {
  z-index: 1;
  height: 72px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 24px;
  box-shadow: 0 1px 9px -3px rgba(0,0,0,0.2);
}
.brand .logo {
  display: flex;
  align-items: center;
  justify-content: center;
}
.brand .logo img {
  width: 36px;
  margin-right: 8px;
}
.brand .logo h1 {
  vertical-align: text-bottom;
  font-size: 16px;
  text-transform: uppercase;
  display: inline-block;
  font-weight: 700;
  color: #5ad;
  white-space: nowrap;
  margin-bottom: 0;
}
.brand .logo h1 :local {
  animation: fadeRightIn 300ms ;
  animation-fill-mode: both;
}

.menu-container {
  height: 100vh;
  overflow-x: hidden;
  flex: 1;
  padding: 24px 0;
}
.menu-container::-webkit-scrollbar-thumb {
  background-color: transparent;
}
.menu-container:hover::-webkit-scrollbar-thumb {
  background-color: rgba(0,0,0,0.2);
}
.menu-container :global .ant-menu-inline {
  border-right: none;
}

.trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

.trigger:hover {
  color: #1890ff;
}

.side-bar, .top-bar {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
</style>
