<template>
  <div>
    <a-form-model layout="inline" ref="form" :model="form">

      <a-form-model-item prop="companyName">
        <a-input @keyup.enter.native="handleSearch" placeholder="公司名" v-model="form.companyName" allowClear />
      </a-form-model-item>

      <a-form-model-item prop="name">
        <a-input @keyup.enter.native="handleSearch" placeholder="用户姓名" v-model="form.name" allowClear />
      </a-form-model-item>

      <a-form-model-item prop="phoneNumber">
        <a-input @keyup.enter.native="handleSearch" placeholder="手机号" v-model="form.phoneNumber" allowClear />
      </a-form-model-item>

      <a-form-model-item prop="email">
        <a-input @keyup.enter.native="handleSearch" placeholder="邮箱" v-model="form.email" allowClear />
      </a-form-model-item>

      <a-form-model-item>
        <a-button type="primary" @click="handleSearch">
          搜索
        </a-button>
      </a-form-model-item>
      <a-form-model-item>
        <a-button @click="handleResetSearchForm">
          重置
        </a-button>
      </a-form-model-item>
    </a-form-model>
  </div>
</template>

<script>
export default {
  name: 'SearchBar',

  props: {
    form: { type: Object, required: true }
  },

  methods: {
    // 搜索
    handleSearch () {
      this.$emit('search')
    },

    // 重置搜索
    handleResetSearchForm () {
      this.$refs.form.resetFields()
      this.handleSearch()
    }
  }
}
</script>

<style scoped>
</style>
