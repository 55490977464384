<template>
  <div>
    <a-form-model layout="inline" ref="form" :model="form">
      <a-form-model-item prop="userID">
        <a-select v-model="form.userID" style="width: 150px" placeholder="用户手机号" mode="default"
                  :not-found-content="null" show-search :filter-option="false" :show-arrow="false"
                  @search="handleSearchUserPhoneNumber" :dropdown-match-select-width="false" allowClear>
          <a-select-option v-for="(item, index) of userPhoneNumberList" :value="item.id" :key="index">
            {{item.name}}
          </a-select-option>
        </a-select>
      </a-form-model-item>

      <a-form-model-item prop="stoneID">
        <a-select v-model="form.stoneID" style="width: 150px" placeholder="品种名" mode="default"
                  :not-found-content="null" show-search :filter-option="false" :show-arrow="false"
                  @search="handleSearchStone" :dropdown-match-select-width="false" allowClear>
          <a-select-option v-for="(item, index) of stoneList" :value="item.id" :key="index">
            {{item.name}}
          </a-select-option>
        </a-select>
      </a-form-model-item>

      <a-form-model-item>
        <a-button type="primary" @click="handleSearch">
          搜索
        </a-button>
      </a-form-model-item>
      <a-form-model-item>
        <a-button @click="handleResetSearchForm">
          重置
        </a-button>
      </a-form-model-item>
    </a-form-model>
  </div>
</template>

<script>
export default {
  name: 'SearchBar',

  props: {
    form: { type: Object, required: true }
  },

  data () {
    return {
      userPhoneNumberList: [],
      stoneList: []
    }
  },

  beforeMount () {
    this.handleSearchUserPhoneNumber('')
    this.handleSearchStone('')
  },

  methods: {
    // 搜索
    handleSearch () {
      this.$emit('search')
    },

    // 重置搜索
    handleResetSearchForm () {
      this.$refs.form.resetFields()
      this.handleSearch()
    },

    // 搜索用户
    handleSearchUserPhoneNumber (keyword, callback) {
      this.api.getSelectOptionList({ category: 'UserPhoneNumber', keyword }).then(res => {
        this.userPhoneNumberList = res.data.getSelectOptionList
        if (callback && typeof (callback) === 'function') {
          this.$nextTick(() => {
            callback()
          })
        }
      })
    },

    // 搜索品种
    handleSearchStone (keyword, callback) {
      this.api.getSelectOptionList({ category: 'Stone', keyword }).then(res => {
        this.stoneList = res.data.getSelectOptionList
        if (callback && typeof (callback) === 'function') {
          this.$nextTick(() => {
            callback()
          })
        }
      })
    }
  }
}
</script>

<style scoped>
</style>
