import { getOSSToken as getNewOSSToken } from '@/request/api'
import store from '../store'
import moment from 'moment'

// 获取阿里云 OSS Token 并缓存
function getOSSToken () {
  return new Promise((resolve, reject) => {
    if (!store.state.ossToken || !store.state.ossToken.expire || moment() > moment.unix(store.state.ossToken.expire)) {
      getNewOSSToken().then(res => {
        if (res.data.getOSSToken) {
          store.commit('setOSSToken', res.data.getOSSToken)
          return resolve(res.data.getOSSToken)
        }
        // eslint-disable-next-line prefer-promise-reject-errors
        return reject('获取token失败')
      }).catch(err => reject(err))
    } else {
      return resolve(store.state.ossToken)
    }
  })
}

export {
  getOSSToken
}
