<template>
  <div>
    <!-- 搜索栏 -->
    <a-row :style="{ marginBottom: '16px' }" type="flex" justify="space-between">
      <search-bar :form="searchForm" @search="handleSearch" />
    </a-row>

    <!-- 表格 -->
    <Table
      :columns="columns"
      :data="list"
      :pageNum="pageNum"
      :pageSize="pageSize"
      v-model="total"
      @pageChange="handlePageChange"
      @pageSizeChange="handlePageSizeChange"
      @tableChange="handleTableChange"
    />
  </div>
</template>

<script>
import Table from '@/components/table/Table'
import page from '@/mixins/page'
import permission from '@/mixins/permission'
import status from '@/mixins/status'
import tool from '@/mixins/tool'
import SearchBar from './SearchBar'

export default {
  name: 'LogList',

  components: { SearchBar, Table },

  mixins: [permission, page, status, tool],

  data () {
    return {
      // 搜索表单
      searchForm: {},

      // 表格列
      columns: [
        { title: '用户ID', dataIndex: 'user.id', scopedSlots: { customRender: 'user-log' } },
        { title: '用户名', dataIndex: 'user.name', scopedSlots: { customRender: 'user-log' } },
        { title: '手机号', dataIndex: 'user.phoneNumber', scopedSlots: { customRender: 'user-log' } },
        {
          title: '类型',
          dataIndex: 'category',
          customRender: text => this.getLogCategoryTag(text),
          filters: [
            { text: '浏览', value: 'Access' },
            { text: '下载图包', value: 'DownImagePack' }
          ],
          onFilter: () => true,
          filterMultiple: false
        },
        { title: '品种名', dataIndex: 'stone.title' },
        { title: '浏览时间', dataIndex: 'createdAt', customRender: this.handleGetDateTime, sorter: true }
      ],

      // 添加或编辑表单
      addForm: {},
      editForm: {
      },
      form: {}
    }
  },

  computed: {
    allFilter () {
      return {
        ...this.filter,
        ...this.searchForm
      }
    },
    // 获取列表的参数
    args () {
      return {
        data: {
          filter: this.allFilter,
          sort: this.sort
        },
        page: {
          pageNum: this.pageNum,
          pageSize: this.pageSize
        }
      }
    }
  },

  mounted () {
    this.getLogList()
  },

  methods: {
    // 搜索
    handleSearch () {
      this.getLogList()
    },

    // 获取数据
    getLogList () {
      this.api.getLogList(this.args).then(res => {
        this.list = res.data.getLogList.data
        this.total = res.data.getLogList.total
      })
    },

    // 换页回调
    handlePageChange (pageNum, pageSize) {
      this.pageNum = pageNum
      this.pageSize = pageSize
      this.getLogList()
    },

    // 页数回调
    handlePageSizeChange (pageNum, pageSize) {
      this.pageNum = pageNum
      this.pageSize = pageSize
      this.getLogList()
    },

    // 表格回调，筛选、排序等
    handleTableChange (...payload) {
      this.handleFilter(...payload)
      this.handleSort(...payload)
      this.getLogList()
    }
  }
}
</script>

<style scoped>

</style>
