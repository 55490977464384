<template>
  <div>
    <a-modal
      :title="title"
      :visible="visible"
      :confirm-loading="loading"
      @ok="handleOk"
      @cancel="handleCancel"
      :centered="true"
      :maskClosable="false"
      :width="800"
    >
      <a-form-model
        :rules="rules"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
        ref="form"
        :model="form"
      >
        <a-form-model-item prop="name" label="管理员姓名">
          <a-input placeholder="管理员姓名" v-model="form.name" />
        </a-form-model-item>

        <a-form-model-item prop="phoneNumber" label="手机号">
          <a-input placeholder="手机号" v-model="form.phoneNumber" />
        </a-form-model-item>

        <a-form-model-item prop="password" label="密码">
          <a-input placeholder="密码" type="password" v-model="form.password" />
        </a-form-model-item>

        <a-form-model-item prop="status" label="管理员状态">
          <a-select v-model="form.status" placeholder="请选择管理员状态">
            <a-select-option v-for="(item, key) of statusMap" :value="key" :key="key">
              {{item.text}}
            </a-select-option>
          </a-select>
        </a-form-model-item>

        <a-divider orientation="left">配置权限</a-divider>

        <a-col :offset="labelCol.span">
          <a-tree
            :checkable="true"
            v-model="form.permissionList"
            :tree-data="permissionList"
          />
        </a-col>

      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import status from '@/mixins/status'
import permission from '@/mixins/permission'

export default {
  name: 'ManagerModal',

  data () {
    return {
      labelCol: { span: 5 },
      wrapperCol: { span: 16 },
      // 表单验证规则
      rules: {
        name: [
          { required: true, message: '请输入管理员姓名', trigger: ['blur', 'change'] },
          { min: 1, max: 20, message: '管理员姓名长度为1-20', trigger: ['blur', 'change'] }
        ],
        phoneNumber: [
          { required: true, message: '请输入手机号', trigger: ['blur', 'change'] },
          { min: 11, max: 11, message: '手机号应为11位', trigger: ['blur', 'change'] }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: ['blur', 'change'] },
          { min: 1, max: 50, message: '密码长度为1-50', trigger: ['blur', 'change'] }
        ],
        status: [
          { required: true, message: '请选择管理员状态', trigger: ['blur', 'change'] }
        ]
      }
    }
  },

  mixins: [permission, status],

  props: {
    title: String,
    visible: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
    form: { type: Object, required: true },
    permissionList: { type: Array, default () { return [] } },
    isEdit: { type: Boolean, default: false }
  },

  methods: {
    // 确认
    handleOk () {
      this.$emit('ok')
    },

    // 取消
    handleCancel () {
      this.$emit('cancel')
    }
  }
}
</script>

<style scoped>

</style>
