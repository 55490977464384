<template>
  <div>
    <a-table
      :columns="columns"
      :data-source="data"
      bordered
      :pagination="false"
      :rowKey="(record, index) => index"
      @change="handleChange"
    >
      <template slot="user-log" slot-scope="text, record">
        <a @click="handleGoUserLog(record)">{{ text }}</a>
      </template>

      <!-- 标签列表 -->
      <template slot="tag-list" slot-scope="record">
        <a-tag class="text-not-selected" v-for="(item, index) of record" :key="index" :style="{ marginBottom: '3px' }">
          {{item.name}}
        </a-tag>
      </template>

      <!-- 筛选 -->
      <template slot="filter-category" slot-scope="text, record">
        <a-tag class="text-not-selected" v-if="record.isColor" :style="{ marginBottom: '3px' }"
               :color="filterCategoryMap.Color.color">色系</a-tag>
        <a-tag class="text-not-selected" v-if="record.isMaterial" :style="{ marginBottom: '3px' }"
               :color="filterCategoryMap.Material.color">材质</a-tag>
        <a-tag class="text-not-selected" v-if="record.isTexture" :style="{ marginBottom: '3px' }"
               :color="filterCategoryMap.Texture.color">纹理</a-tag>
      </template>

      <!-- 单张图片，value必须是url -->
      <template slot="image" slot-scope="text">
        <a-avatar @click="handlePreview(text+'?x-oss-process=image/resize,w_500,m_lfit')" shape="square" size="large" :src="text+'?x-oss-process=image/resize,w_100,m_lfit'" :alt="text" style="cursor: pointer" />
      </template>

      <!-- 操作：默认包含编辑和删除 -->
      <template slot="operation" slot-scope="text, record">
        <a-row type="flex" justify="space-between" style="max-width: 150px">
            <a-button size="small" icon="edit" style="margin-bottom: 5px;" @click="handleEdit(record)" v-if="getPermission('edit')">
              编辑
            </a-button>
            <a-popconfirm v-if="getPermission('delete')"
              title="确定删除"
              @confirm="() => handleDel(record)"
            >
              <a-button size="small" type="danger" icon="delete">
                删除
              </a-button>
            </a-popconfirm>
        </a-row>
      </template>

      <!-- 操作：只编辑 -->
      <template slot="operation.edit" slot-scope="text, record">
        <a-row type="flex" justify="space-between" style="max-width: 150px">
          <a-button size="small" icon="edit" style="margin-bottom: 5px;" @click="handleEdit(record)" v-if="getPermission('edit')">
            编辑
          </a-button>
        </a-row>
      </template>

      <!-- 操作：编辑和修改权重 -->
      <template slot="operation.weight" slot-scope="text, record">
        <a-row type="flex" justify="space-between" style="max-width: 170px">
          <a-button size="small" icon="edit" style="margin-bottom: 5px;" @click="handleEdit(record)" v-if="getPermission('edit')">
            编辑
          </a-button>
          <a-button size="small" icon="edit" style="margin-bottom: 5px;" @click="handleEditWeight(record)" v-if="getPermission('edit')">
            修改权重
          </a-button>
        </a-row>
      </template>
    </a-table>

    <a-row type="flex" justify="end" :style="{ margin: '16px 0' }">
      <a-pagination
        :total="total"
        :current="pageNum"
        :pageSize="pageSize"
        :showSizeChanger="true"
        :showQuickJumper="true"
        :showTotal="total => `总共 ${total} 条记录 `"
        :pageSizeOptions="['10', '20', '30', '50']"
        @change="handlePageChange"
        @showSizeChange="handlePageSizeChange"
      ></a-pagination>
    </a-row>

    <!-- 预览图片 -->
    <a-modal :visible="previewVisible" :centered="true" :footer="null" @cancel="() => this.previewVisible = false"
             :closable="false">
      <img alt="图片预览" style="width: 100%" :src="previewImage" />
    </a-modal>
  </div>
</template>

<script>
import permission from '@/mixins/permission'
import status from '@/mixins/status'

export default {
  name: 'Table',

  mixins: [permission, status],

  props: {
    columns: { type: Array, required: true },
    data: { type: Array, required: true },
    pageNum: { type: Number, default: 1 },
    pageSize: { type: Number, default: 10 },
    total: Number
  },

  data () {
    return {
      // 图片预览 modal
      previewVisible: false,
      // 预览的图片地址
      previewImage: ''
    }
  },

  model: {
    prop: 'total',
    event: 'change'
  },

  methods: {
    // 换页回调
    handlePageChange (pageNum, pageSize) {
      this.$emit('pageChange', pageNum, pageSize)
    },

    // 页数调整回调
    handlePageSizeChange (pageNum, pageSize) {
      this.$emit('pageSizeChange', pageNum, pageSize)
    },

    // 携带id跳转用户日志页
    handleGoUserLog (record) {
      const userID = record.userID ? record.userID : record.id
      this.$router.push({
        path: '/user-log-list',
        query: {
          id: userID
        }
      })
    },

    // 编辑
    handleEdit (record) {
      this.$emit('recordEdit', record)
    },

    // 删除
    handleDel (record) {
      this.$emit('recordDel', record)
    },

    // 修改权重
    handleEditWeight (record) {
      this.$emit('changeWeight', record)
    },

    // 预览图片
    async handlePreview (url) {
      this.previewImage = url
      this.previewVisible = true
    },

    // 表格改变回调
    handleChange (...payload) {
      this.$emit('tableChange', ...payload)
    }
  }
}
</script>

<style scoped>

</style>
