import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import Home from '../pages/Home.vue'
import moment from 'moment'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: Home,
    children: [
      { path: '/', name: 'Dashboard', component: () => import('@/pages/dashboard/Dashboard'), meta: { title: '仪表盘' } },
      { path: '/user-list', name: 'UserList', component: () => import('@/pages/user/UserList'), meta: { title: '用户管理' } },
      { path: '/manager-list', name: 'ManagerList', component: () => import('@/pages/manager/ManagerList'), meta: { title: '管理人员' } },
      { path: '/tag-list', name: 'TagList', component: () => import('@/pages/tag/TagList'), meta: { title: '标签管理' } },
      { path: '/token-list', name: 'TokenList', component: () => import('@/pages/token/TokenList'), meta: { title: '代币管理' } },
      { path: '/stone-list', name: 'StoneList', component: () => import('@/pages/stone/StoneList'), meta: { title: '石材管理' } },
      { path: '/order-list', name: 'OrderList', component: () => import('@/pages/order/OrderList'), meta: { title: '订单管理' } },
      { path: '/image-list', name: 'ImageList', component: () => import('@/pages/image/ImageList'), meta: { title: '图片管理' } },
      { path: '/log-list', name: 'LogList', component: () => import('@/pages/log/LogList'), meta: { title: '浏览记录' } },
      { path: '/user-log-list', name: 'UserLogList', component: () => import('@/pages/log/UserLogList'), meta: { title: '用户浏览记录' }, props: route => ({ id: route.query.id }) }
    ]
  },
  { path: '/login', name: 'Login', component: () => import('@/pages/login/Login.vue'), meta: { title: '登录' } }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  // 路由发生变化修改页面title
  if (to.meta.title) document.title = to.meta.title + ' | 石上良品'
  if (to.name === 'Login') return next()
  // 保存当前页面
  store.commit('setCurrentPath', to.path)
  // 没有token就跳转登录
  if (!store.state.token) return router.push('/login').then()
  // token过期也跳转
  if (store.state.tokenExpire < moment().format('X')) return router.push('/login').then()
  // 检验路由，如果不在权限列表则打回首页
  if (to.path !== '/' && !store.state.permissionMap[to.path]) return router.push('/').then()
  next()
})

export default router
