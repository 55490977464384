<template>
  <div id="app">
      <!-- 页面内容 -->
      <keep-alive>
        <a-config-provider :locale="locale">
          <router-view v-if="$route.meta.keepAlive"></router-view>
        </a-config-provider>
      </keep-alive>
      <a-config-provider :locale="locale">
        <router-view v-if='!$route.meta.keepAlive'></router-view>
      </a-config-provider>
  </div>
</template>

<script>
import zhCN from 'ant-design-vue/es/locale/zh_CN'

export default {
  name: 'App',

  data () {
    return {
      locale: zhCN
    }
  }
}

</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
