<template>
  <div>
    <a-modal
      :title="title"
      :visible="visible"
      :confirm-loading="loading"
      @ok="handleOk"
      @cancel="handleCancel"
      :centered="true"
      :maskClosable="false"
      :width="800"
    >
      <a-form-model
        :rules="rules"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
        ref="form"
        :model="form"
      >
        <a-form-model-item prop="source" label="代币来源">
          <a-select v-model="form.source" placeholder="请选择代币来源">
            <a-select-option v-for="(item, key) of sourceMap" :value="key" :key="key">
              {{item.text}}
            </a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-model-item prop="value" label="代币数量">
          <a-input-number placeholder="代币数量" v-model="form.value" :min="-999999999" :max="999999999"/>
        </a-form-model-item>

        <a-form-model-item prop="userID" label="目标用户">
          <a-select v-model="form.userID"
                    placeholder="请选择目标用户"
                    :showSearch="true" optionFilterProp="children"
          >
            <a-select-option v-for="(item, key) of userList" :value="item.id" :key="key">
              {{item.name ? item.name + '-' : '' }}{{item.phoneNumber}}
            </a-select-option>
          </a-select>
        </a-form-model-item>

      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import status from '@/mixins/status'
import permission from '@/mixins/permission'

export default {
  name: 'TokenModal',

  data () {
    return {
      labelCol: { span: 5 },
      wrapperCol: { span: 16 },
      // 表单验证规则
      rules: {
        source: [
          { required: true, message: '请选择代币来源', trigger: ['blur', 'change'] }
        ],
        value: [
          { required: true, message: '请输入代币数量', trigger: ['blur', 'change'] }
        ],
        userID: [
          { required: true, message: '请选择绑定用户', trigger: ['blur', 'change'] }
        ]
      }
    }
  },

  mixins: [permission, status],

  props: {
    title: String,
    visible: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
    form: { type: Object, required: true },
    isEdit: { type: Boolean, default: false },
    userList: { type: Array, default () { return [] } }
  },

  methods: {
    // 确认
    handleOk () {
      this.$emit('ok')
    },

    // 取消
    handleCancel () {
      this.$emit('cancel')
    }
  }
}
</script>

<style scoped>

</style>
