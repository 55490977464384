<template>
  <div>
    <a-modal
      :title="title"
      :visible="visible"
      :confirm-loading="loading"
      @ok="handleOk"
      @cancel="handleCancel"
      :centered="true"
      :maskClosable="false"
      :width="800"
    >
      <a-form-model
        :rules="rules"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
        ref="form"
        :model="form"
      >
        <a-form-model-item prop="tradeStatus" label="订单状态">
          <a-select v-model="form.tradeStatus" placeholder="请选择订单状态">
            <a-select-option v-for="(item, key) of tradeStatusMap" :value="key" :key="key">
              {{item.text}}
            </a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-model-item prop="orderNo" label="订单号">
          <a-input placeholder="订单号" disabled v-model="form.orderNo" />
        </a-form-model-item>

        <a-form-model-item prop="transactionID" label="支付单号">
          <a-input placeholder="微信订单号" disabled v-model="form.transactionID" />
        </a-form-model-item>

        <a-form-model-item prop="totalPrice" label="订单总价">
          <a-input-number placeholder="订单总价" v-model="form.totalPrice" :min="0" :max="999999999"/>
        </a-form-model-item>

        <a-form-model-item prop="usedToken" label="使用代币">
          <a-input-number placeholder="使用代币数量" disabled v-model="form.usedToken" :min="0" :max="999999999"/>
        </a-form-model-item>

        <a-form-model-item prop="paidPrice" label="实付价格" v-if="form.tradeStatus === 'Paid'"
                           :rules="form.tradeStatus === 'Paid' ? optRules.paidPrice : {}">
          <a-input-number placeholder="实付价格" v-model="form.paidPrice" :min="0" :max="999999999"/>
        </a-form-model-item>

        <a-form-model-item prop="username" label="收件人">
          <a-input placeholder="收件人" v-model="form.username" />
        </a-form-model-item>

        <a-form-model-item prop="phoneNumber" label="联系方式">
          <a-input placeholder="联系方式" v-model="form.phoneNumber" />
        </a-form-model-item>

        <a-form-model-item prop="address" label="收件地址">
          <a-input placeholder="收件地址" v-model="form.address" />
        </a-form-model-item>

        <a-form-model-item prop="user.name" label="下单人" v-if="visible && form.user && form.user.name">
          <a-input placeholder="下单人" disabled v-model="form.user.name" />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import status from '@/mixins/status'
import permission from '@/mixins/permission'

export default {
  name: 'OrderModal',

  data () {
    return {
      labelCol: { span: 5 },
      wrapperCol: { span: 16 },
      // 表单验证规则
      rules: {
        tradeStatus: [
          { required: true, message: '请选择订单状态', trigger: ['blur', 'change'] }
        ],
        orderNo: [
          { required: true, message: '请输入订单号', trigger: ['blur', 'change'] }
        ],
        totalPrice: [
          { required: true, message: '请输入订单总价', trigger: ['blur', 'change'] }
        ],
        usedToken: [
          { required: true, message: '请输入使用的代币数', trigger: ['blur', 'change'] }
        ],
        username: [
          { required: true, message: '请输入收件人', trigger: ['blur', 'change'] },
          { min: 1, max: 20, message: '收件人长度为1-20', trigger: ['blur', 'change'] }
        ],
        phoneNumber: [
          { required: true, message: '请输入联系方式', trigger: ['blur', 'change'] },
          { min: 11, max: 11, message: '联系方式应为11位', trigger: ['blur', 'change'] }
        ],
        address: [
          { required: true, message: '请输入收件地址', trigger: ['blur', 'change'] },
          { min: 1, max: 50, message: '收件地址长度为1-50', trigger: ['blur', 'change'] }
        ]
      },
      optRules: {
        paidPrice: [
          { required: true, message: '请输入实付金额', trigger: ['blur', 'change'] }
        ]
      }
    }
  },

  mixins: [permission, status],

  props: {
    title: String,
    visible: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
    form: { type: Object, required: true },
    isEdit: { type: Boolean, default: false }
  },

  methods: {
    // 确认
    handleOk () {
      this.$emit('ok')
    },

    // 取消
    handleCancel () {
      this.$emit('cancel')
    }
  }
}
</script>

<style scoped>

</style>
