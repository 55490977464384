<template>
  <div>
    <!-- 搜索栏 -->
    <a-row :style="{ marginBottom: '16px' }" type="flex" justify="space-between">
      <search-bar :form="searchForm" @search="handleSearch" />
      <a-col v-if="getPermission('add')">
        <a-button @click="handleShowModal(null)">添加</a-button>
      </a-col>
    </a-row>

    <!-- 表格 -->
    <Table
      :columns="columns"
      :data="list"
      :pageNum="pageNum"
      :pageSize="pageSize"
      v-model="total"
      @pageChange="handlePageChange"
      @pageSizeChange="handlePageSizeChange"
      @recordEdit="handleShowModal"
      @recordDel="handleDel"
      @tableChange="handleTableChange"
    />

    <!-- 添加和编辑框 -->
    <manager-modal
      ref="modal"
      :title="modalTitle"
      :visible="modalVisible"
      :loading="modalLoading"
      :form="form"
      :permissionList="permissionList"
      :isEdit="isEdit"
      @ok="handleAddOrEdit"
      @cancel="handleCloseModal"
    />
  </div>
</template>

<script>
import Table from '@/components/table/Table'
import page from '@/mixins/page'
import permission from '@/mixins/permission'
import status from '@/mixins/status'
import tool from '@/mixins/tool'
import SearchBar from './SearchBar'
import ManagerModal from './ManagerModal'
import { copy, deepCopy } from '@/utils/object'

export default {
  name: 'ManagerList',

  components: { SearchBar, Table, ManagerModal },

  mixins: [permission, page, status, tool],

  data () {
    return {
      // 搜索表单
      searchForm: {
        name: '',
        phoneNumber: ''
      },

      // 权限列表
      permissionList: [],

      // 表格列
      columns: [
        { title: '姓名', dataIndex: 'name' },
        { title: '手机号', dataIndex: 'phoneNumber' },
        { title: '管理员类型', dataIndex: 'isSuperManager', customRender: text => this.getManagerTypeTag(text) },
        {
          title: '状态',
          dataIndex: 'status',
          customRender: text => this.getStatusTag(text),
          filters: [
            { text: '可用', value: 'Available' },
            { text: '不可用', value: 'Disabled' }
          ],
          onFilter: () => true,
          filterMultiple: false
        },
        { title: '添加时间', dataIndex: 'createdAt', customRender: this.handleGetDateTime, sorter: true }
      ],

      modalVisible: false,
      modalLoading: false,
      addModalTitle: '添加管理员',
      editModalTitle: '编辑管理员信息',
      isEdit: false,

      // 添加或编辑表单
      addForm: {
        name: '',
        phoneNumber: '',
        password: '',
        status: 'Disabled',
        permissionList: []
      },
      editForm: {
        id: 0,
        name: '',
        phoneNumber: '',
        password: '',
        status: 'Disabled',
        permissionList: []
      },
      form: {}
    }
  },

  computed: {
    modalTitle () {
      return this.isEdit ? this.editModalTitle : this.addModalTitle
    },
    // 获取列表的参数
    args () {
      return {
        manager: {
          name: this.searchForm.name,
          phoneNumber: this.searchForm.phoneNumber,
          filter: this.filter,
          sort: this.sort
        },
        page: {
          pageNum: this.pageNum,
          pageSize: this.pageSize
        }
      }
    }
  },

  mounted () {
    if (this.getPermission('edit') && this.getPermission('delete')) {
      this.columns.push({
        title: '操作',
        dataIndex: 'operation',
        scopedSlots: { customRender: 'operation' }
      })
    }

    this.getPermissionList()
    this.getManagerList()
  },

  methods: {
    // 搜索
    handleSearch () {
      this.getManagerList()
    },

    // 打开弹框
    handleShowModal (record = undefined) {
      if (record) {
        this.form = deepCopy(this.editForm)
        copy(this.form, record)
        this.isEdit = true
      } else {
        this.form = deepCopy(this.addForm)
        this.isEdit = false
      }
      this.modalVisible = true
    },

    // 关闭弹框
    handleCloseModal () {
      this.modalVisible = false
      // 去掉提示
      this.$refs.modal.$refs.form.resetFields()
    },

    // 添加或编辑
    handleAddOrEdit () {
      this.$refs.modal.$refs.form.validate(valid => {
        if (!valid) return
        if (this.isEdit) {
          this.handleEdit(deepCopy(this.form))
        } else {
          delete this.form.id
          this.handleAdd(deepCopy(this.form))
        }
      })
    },

    // 添加数据
    handleAdd (form) {
      this.api.addManager(form).then(res => {
        if (res.data.addManager.id) {
          this.$message.success('添加成功')
          this.getManagerList()
          this.handleCloseModal()
        }
      })
    },

    // 编辑数据
    handleEdit (form) {
      this.api.editManager(form).then(res => {
        try {
          if (res.data.editManager.rowsAffected >= 0) {
            this.$message.success('编辑成功')
            this.getManagerList()
            this.handleCloseModal()
            return
          }
          this.$message.error('编辑失败')
        } catch (e) {
          this.$message.error('编辑失败')
        }
      })
    },

    // 删除数据
    handleDel (record) {
      this.api.delManager(record.id).then(res => {
        try {
          const c = res.data.delManager.rowsAffected
          if (c !== undefined && c >= 0) {
            this.$message.success('删除成功')
            this.getManagerList()
            return
          }
          this.$message.error('删除失败')
        } catch (e) {
          this.$message.error('删除失败')
        }
      })
    },

    // 获取数据
    getManagerList () {
      this.api.getManagerList(this.args).then(res => {
        this.list = res.data.getManagerList.data
        this.total = res.data.getManagerList.total
      })
    },

    // 获取权限数据
    getPermissionList () {
      this.api.getPermissionList().then(res => {
        // 目前只有两级
        // [{ title: '仪表盘', key: '/', children: [{ title: '添加', key: '/-add' }, { title: '查看', key: '/-read' },
        //  { title: '编辑', key: '/-edit' }, { title: '删除', key: '/-delete' }] }]
        const permissionMap = {}
        const parentMap = {}
        const permissionList = []

        // 做映射，并提取根节点
        for (const v of res.data.getPermissionList) {
          if (v.parentID === 0) {
            parentMap[v.id] = permissionList.length
            permissionList.push({ title: v.title, key: v.key, children: [] })
            continue
          }
          permissionMap[v.id] = v
        }

        // 将子节点做进去
        for (const id in permissionMap) {
          const v = permissionMap[id]
          const i = parentMap[v.parentID]
          if (i !== undefined) {
            permissionList[i].children.push({ title: v.title, key: v.key })
          }
        }

        this.permissionList = permissionList
      })
    },

    // 换页回调
    handlePageChange (pageNum, pageSize) {
      this.pageNum = pageNum
      this.pageSize = pageSize
      this.getManagerList()
    },

    // 页数回调
    handlePageSizeChange (pageNum, pageSize) {
      this.pageNum = pageNum
      this.pageSize = pageSize
      this.getManagerList()
    },

    // 表格回调，筛选、排序等
    handleTableChange (...payload) {
      this.handleFilter(...payload)
      this.handleSort(...payload)
      this.getManagerList()
    }
  }
}
</script>

<style scoped>

</style>
