<template>
  <div>
    <a-modal
      :title="title"
      :visible="visible"
      :confirm-loading="loading"
      @ok="handleOk"
      @cancel="handleCancel"
      :centered="true"
      :maskClosable="false"
      :width="800"
    >
      <a-form-model
        :rules="rules"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
        ref="form"
        :model="form"
      >
        <a-form-model-item prop="companyName" label="公司名">
          <a-input placeholder="公司名" v-model="form.companyName" />
        </a-form-model-item>

        <a-form-model-item prop="name" label="用户名称">
          <a-input placeholder="用户名称" v-model="form.name" />
        </a-form-model-item>

        <a-form-model-item prop="gender" label="性别">
          <a-select v-model="form.gender" placeholder="请选择性别">
            <a-select-option v-for="(item, key) of genderMap" :value="key" :key="key">
              {{item.text}}
            </a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-model-item prop="phoneNumber" label="手机号">
          <a-input placeholder="手机号" v-model="form.phoneNumber" />
        </a-form-model-item>

        <a-form-model-item prop="email" label="邮箱">
          <a-input placeholder="邮箱" v-model="form.email" />
        </a-form-model-item>

        <a-form-model-item prop="password" label="密码">
          <a-input placeholder="密码" type="password" v-model="form.password" />
        </a-form-model-item>

        <a-form-model-item prop="token" label="代币数量">
          <a-input-number placeholder="分配给该用户的代币数量" v-model="form.token" :min="0" :max="999999999"/>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import status from '@/mixins/status'
import permission from '@/mixins/permission'

export default {
  name: 'UserModal',

  data () {
    return {
      labelCol: { span: 5 },
      wrapperCol: { span: 16 },
      // 表单验证规则
      rules: {
        companyName: [
          { required: true, message: '请输入公司名', trigger: ['blur', 'change'] },
          { min: 1, max: 50, message: '公司名长度为1-50', trigger: ['blur', 'change'] }
        ],
        name: [
          { required: true, message: '请输入用户名称', trigger: ['blur', 'change'] },
          { min: 1, max: 20, message: '用户名称长度为1-20', trigger: ['blur', 'change'] }
        ],
        gender: [
          { required: true, message: '请选择用户性别', trigger: ['blur', 'change'] }
        ],
        phoneNumber: [
          { required: true, message: '请输入手机号', trigger: ['blur', 'change'] },
          { min: 11, max: 11, message: '手机号应为11位', trigger: ['blur', 'change'] }
        ],
        email: [
          { required: true, message: '请输入邮箱', trigger: ['blur', 'change'] },
          { min: 1, max: 50, message: '邮箱长度应为1-50', trigger: ['blur', 'change'] }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: ['blur', 'change'] },
          { min: 1, max: 50, message: '密码长度为1-50', trigger: ['blur', 'change'] }
        ],
        token: [
          { required: true, message: '请输入可用代币数', trigger: ['blur', 'change'] }
        ]
      }
    }
  },

  mixins: [permission, status],

  props: {
    title: String,
    visible: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
    form: { type: Object, required: true },
    isEdit: { type: Boolean, default: false }
  },

  methods: {
    // 确认
    handleOk () {
      this.$emit('ok')
    },

    // 取消
    handleCancel () {
      this.$emit('cancel')
    }
  }
}
</script>

<style scoped>

</style>
