<template>
  <div>
    <a-modal
      :title="title"
      :visible="visible"
      :confirm-loading="loading"
      @ok="handleOk"
      @cancel="handleCancel"
      :centered="true"
      :maskClosable="false"
      :width="800"
    >
      <a-form-model
        :rules="rules"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
        ref="form"
        :model="form"
      >
        <a-form-model-item prop="image" label="石材主图">
          <upload :form="form" field="image" :limit="1" />
        </a-form-model-item>

        <a-form-model-item prop="imagePack" label="图包">
          <upload-pack :form="form" field="imagePack" :limit="1" />
        </a-form-model-item>

        <a-form-model-item prop="images" label="石材图片">
          <draggable-upload :form="form" field="images" button-title="上传图片" />
        </a-form-model-item>

        <a-form-model-item prop="tags" label="相关标签">
            <a-select v-model="form.tags" placeholder="石材相关标签，打字可搜索" mode="multiple" :not-found-content="null"
                      show-search :filter-option="false" :show-arrow="false" @search="handleSearchTag">
            <a-select-option v-for="(item, index) of tagSelectOptionList" :value="item.id" :key="index">
              {{item.name}}
            </a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-model-item prop="title" label="石材标题">
          <a-input placeholder="石材标题" v-model="form.title" />
        </a-form-model-item>

        <a-form-model-item prop="content" label="文字信息">
          <vue-editor style="line-height: 22px !important;" :editorToolbar="customToolbar"
                      use-custom-image-handler
                      @image-added="handleUploadImage"
                      v-if="visible" v-model="form.content" />
        </a-form-model-item>

        <a-form-model-item prop="price" label="样品价格">
          <a-input-number placeholder="样品价格" v-model="form.price" style="width: 100%" :min="0" :max="999999999"/>
        </a-form-model-item>

        <a-form-model-item prop="totalCount" label="样品库存">
          <a-input placeholder="样品库存" v-model="form.totalCount" />
        </a-form-model-item>

        <a-form-model-item prop="weight" label="权重">
          <a-input placeholder="权重" v-model="form.weight" />
        </a-form-model-item>

        <a-form-model-item v-for="(contact, index) in form.contacts" :key="index"
                           :prop="'contacts.' + index + '.value'" :label="index === 0 ? '联系方式' : ''"
                           v-bind="index === 0 ? { labelCol, wrapperCol } : formItemLayout"
                           :rules="{ required: true, message: '请输入联系方式', trigger: ['blur'] }"
        >
          <a-input placeholder="联系方式" v-model="contact.value" style="width: 85%; margin-right: 8px" />
          <a-icon
            class="operation-btn"
            type="plus-circle"
            @click="handleAddContact(index)"
          />
          <a-icon
            v-if="form.contacts.length > 1"
            class="operation-btn"
            type="minus-circle-o"
            :disabled="form.contacts.length === 1"
            @click="handleRemoveContact(index)"
          />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import status from '@/mixins/status'
import permission from '@/mixins/permission'
import Upload from '@/components/upload/Upload'
import DraggableUpload from '@/components/upload/DraggableUpload'
import { deepCopy } from '@/utils/object'
import { removeDuplication } from '@/utils/array'
import { uploadToOSS } from '@/utils/image'
import { getOSSToken } from '@/utils/oss'
import { VueEditor } from 'vue2-editor'
import UploadPack from '@/components/upload/UploadPack'

export default {
  name: 'StoneModal',

  components: { UploadPack, Upload, DraggableUpload, VueEditor },

  data () {
    return {
      labelCol: { span: 5 },
      wrapperCol: { span: 16 },
      formItemLayout: {
        wrapperCol: { span: 16, offset: 5 }
      },
      // 表单验证规则
      rules: {
        image: [
          { required: true, message: '请上传石材主图', trigger: ['blur'] }
        ],
        imagePack: [
          { required: false, message: '请上传图包', trigger: ['blur'] }
        ],
        images: [
          { required: true, message: '请上传石材图片', trigger: ['blur'] }
        ],
        tags: [
          { required: true, message: '请选择石材相关标签', trigger: ['blur', 'change'] }
        ],
        title: [
          { required: true, message: '请输入石材标题', trigger: ['blur', 'change'] },
          { min: 1, max: 100, message: '石材标题长度为1-100', trigger: ['blur', 'change'] }
        ],
        content: [
          { required: true, message: '请输入石材图文信息', trigger: ['blur', 'change'] },
          { min: 1, max: 5000, message: '石材图文信息长度为1-5000', trigger: ['blur', 'change'] }
        ],
        price: [
          { required: true, message: '请输入石材样品价格', trigger: ['blur', 'change'] }
        ],
        totalCount: [
          { required: true, message: '请输入石材样品库存', trigger: ['blur', 'change'] }
        ],
        weight: [
          { required: true, message: '请输入权重', trigger: ['blur', 'change'] }
        ]
      },

      // 用于Select的标签选项列表
      tagSelectOptionList: [],

      customToolbar: [
        [{ header: [false, 1, 2, 3, 4, 5, 6] }], ['bold', 'italic', 'underline', 'strike'],
        [{ align: '' }, { align: 'center' }, { align: 'right' }], ['blockquote', 'code-block'],
        [{ list: 'ordered' }, { list: 'bullet' }, { list: 'check' }], [{ indent: '-1' }, { indent: '+1' }],
        [{
          color: ['#fff', '#000000', '#d06d4a', '#4e2f98',
            '#667f86', '#626161', '#8957ad', '#1d98b9', '#D14747', '#489670', '#b9ad66']
        }, {
          background: ['#fff', '#000000', '#d06d4a', '#4e2f98',
            '#667f86', '#626161', '#8957ad', '#1d98b9', '#D14747', '#489670', '#b9ad66']
        }],
        ['clean'], ['image']
      ]
    }
  },

  mixins: [permission, status],

  props: {
    title: String,
    visible: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
    form: { type: Object, required: true },
    isEdit: { type: Boolean, default: false }
  },

  watch: {
    visible: function (val) {
      // 刚进来先填充一下标签选择
      if (val === true) {
        if (this.isEdit) {
          // 如果是编辑就把编辑的也带上，和服务端返回的拼接
          const that = this
          const tags = deepCopy(this.form.tags.map(item => ({
            id: item.tagID,
            name: item.name
          })))
          this.form.tags = this.form.tags.map(item => item.tagID)
          // 如果是编辑则使用默认已选择的
          this.handleSearchTag('', function () {
            that.tagSelectOptionList = removeDuplication(that.tagSelectOptionList.concat(tags), 'id')
          })
        } else {
          // 如果是添加则直接搜索一次
          this.handleSearchTag('')
        }
      }

      if (this.form.contacts.length === 0) {
        this.form.contacts = [{
          value: '',
          key: Date.now()
        }]
      } else {
        for (const i of this.form.contacts.keys()) {
          this.form.contacts[i] = {
            value: this.form.contacts[i],
            key: Date.now()
          }
        }
      }
    }
  },

  methods: {
    // 确认
    handleOk () {
      const contacts = this.form.contacts.map(item => item.value).filter(item => item !== '')
      if (contacts.length === 0) return this.$message.warn('请填写联系方式')
      this.$refs.form.validate(valid => {
        this.$emit('ok')
      })
    },

    // 取消
    handleCancel () {
      this.$emit('cancel')
    },

    // 搜索标签
    handleSearchTag (keyword, callback) {
      this.api.getSelectOptionList({ category: 'Tag', keyword }).then(res => {
        this.tagSelectOptionList = res.data.getSelectOptionList
        if (callback && typeof (callback) === 'function') {
          this.$nextTick(() => {
            callback()
          })
        }
      })
    },

    // 删除联系人
    handleRemoveContact (idx) {
      this.form.contacts.splice(idx, 1)
    },
    handleAddContact (idx) {
      this.form.contacts.splice(idx + 1, 0, {
        value: '',
        key: Date.now()
      })
    },

    // 上传图片
    handleUploadImage (file, Editor, cursorLocation, resetUploader) {
      if (!this.handleBeforeUpload(file)) return

      // 显示进度条
      this.isUpload = true
      this.percent = 1
      const speed = 100 / (file.size / 65000)
      const uploading = setInterval(() => {
        if (this.percent < 100) {
          this.percent += speed
        }
      }, 100)

      getOSSToken().then(ossToken => {
        if (ossToken) {
          uploadToOSS(file, ossToken)
            .then(url => {
              if (url) {
                clearInterval(uploading)
                this.isUpload = false
                // 获取图片信息，返回宽和高
                this.getImageInfo(file, (info) => {
                  this.form.images.push({
                    url,
                    height: info.height || 0,
                    width: info.width || 0,
                    sort: this.form.images.length,
                    weight: 0
                  })
                })
                Editor.insertEmbed(cursorLocation, 'image', url)
                resetUploader()
              } else {
                this.$message.error('上传失败')
              }
            })
            .catch(() => {
              this.isUpload = false
              this.$message.error('上传失败')
            })
        } else {
          this.isUpload = false
          this.$message.error('上传失败')
        }
      }).catch(() => {
        this.isUpload = false
        this.$message.error('上传失败')
      })
    },

    // 上传前拦截
    handleBeforeUpload (file) {
      const isSuffixValid = /^image\/jp[e]+g|image\/png|image\/gif$/.test(file.type)
      if (!isSuffixValid) {
        this.$message.error('只能上传图片')
      }
      // const isLt2M = file.size / 1024 / 1024 < 2
      // if (!isLt2M) {
      //   this.$message.error('Image must smaller than 2MB!')
      // }
      return isSuffixValid
    },

    // 获取图片宽高
    getImageInfo (file, callback) {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = function () {
        const img = new Image()
        img.src = reader.result
        if (img.complete) {
          // eslint-disable-next-line standard/no-callback-literal
          callback({ height: img.height, width: img.width })
          return
        }
        img.onload = () => {
          // eslint-disable-next-line standard/no-callback-literal
          callback({ height: img.height, width: img.width })
        }
      }
    }
  }
}
</script>

<style scoped>
.operation-btn {
  margin-right: 8px;
  cursor: pointer;
  font-size: 20px;
}
.operation-btn:hover {
  color: var(--main-color);
}
.operation-btn[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
}
</style>
