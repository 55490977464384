<template>
  <a-card
    class="number-card"
    :bordered="false"
    :bodyStyle="{ padding: 10 }"
  >
    <template v-if="isIcon">
      <a-icon class="icon-wrap" :type="icon" :style="{ color: iconColor }" />
      <div class="content">
        <div class="title">{{ title }}</div>
        <div class="number">
          <i-count-up
            :delay="0"
            :endVal="number"
            :options="options"
          />
        </div>
      </div>
    </template>

    <div v-else class="content-without-icon">
      <div class="title">{{ title }}</div>
      <div class="number">
        <i-count-up
          :delay="0"
          :endVal="number"
          :options="options"
        />
      </div>
    </div>
  </a-card>
</template>

<script>
import ICountUp from 'vue-countup-v2'

export default {
  name: 'NumberCard',

  components: {
    ICountUp
  },

  data () {
    return {
      options: {
        useEasing: true,
        useGrouping: true,
        duration: 0.5,
        separator: ',',
        decimal: '.',
        decimalPlaces: this.isDecimal ? 6 : 0,
        prefix: this.prefix || '',
        suffix: this.suffix || ''
      }
    }
  },

  computed: {
    isIcon () {
      return this.icon !== ''
    }
  },

  props: {
    title: { type: String, required: true },
    number: { type: Number, required: true },
    icon: { type: String, default: '' },
    iconColor: { type: String, default: '#333' },
    prefix: { type: String, default: '' },
    suffix: { type: String, default: '' },
    isDecimal: { type: Boolean, default: false }
  }
}
</script>

<style scoped>
.number-card {
  padding: 10px 0;
  cursor: pointer;
  display: flex;
  /*justify-content: center;*/
  border: 1px solid var(--border-color);
}
.number-card .icon-wrap {
  font-size: 54px;
  float: left;
}
.number-card .content {
  width: 100%;
  padding-left: 78px;
  padding-right: 20px;
}
.number-card .content .title {
  line-height: 16px;
  font-size: 16px;
  margin-bottom: 8px;
  height: 16px;
}
.number-card .content .number {
  line-height: 32px;
  font-size: 24px;
  height: 32px;
  margin-bottom: 0;
}
.number-card:hover {
  box-shadow: 0 0 20px 3px rgba(150, 150, 150, 0.1);
}

.number-card .content-without-icon {
  width: 100%;
}
.number-card .content-without-icon .title {
  line-height: 16px;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 8px;
  height: 16px;
}
.number-card .content-without-icon .number {
  line-height: 32px;
  font-size: 24px;
  height: 32px;
  font-weight: bold;
  margin-bottom: 0;
  color: var(--main-color);
}
</style>
