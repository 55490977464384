<template>
  <div>
    <a-upload
      list-type="picture-card"
      name="file"
      :multiple="false"
      :file-list="form[field]"
      :customRequest="handleUploadImage"
      @preview="handlePreview"
      :remove="handleRemove"
      :before-upload="handleBeforeUpload"
      :disabled="isUpload"
    >
      <a-progress v-if="isUpload" :show-info="false" :percent="percent" />
      <div v-if="limit > 0 && form[field].length < limit">
        <a-icon type="plus" />
        <div class="ant-upload-text">
          Upload
        </div>
      </div>

      <div v-if="limit <= 0">
        <a-icon type="plus" />
        <div class="ant-upload-text">
          Upload
        </div>
      </div>
    </a-upload>

    <!-- 预览图片 -->
    <a-modal :visible="previewVisible" :centered="true" :footer="null" @cancel="() => this.previewVisible = false">
      <img alt="图片预览" style="width: 100%" :src="previewImage" />
    </a-modal>
  </div>
</template>

<script>
import { newImage, getImgB64FromURL, uploadToOSS } from '@/utils/image'
import { getOSSToken } from '@/utils/oss'

export default {
  name: 'Upload',

  data () {
    return {
      // 图片预览 modal
      previewVisible: false,
      // 预览的图片地址
      previewImage: '',

      // 是否上传
      isUpload: false,
      // 上传进度
      percent: 0
    }
  },

  props: {
    form: { type: Object, required: true },
    field: { type: String, required: true },
    limit: { type: Number, default: -1 }
  },

  methods: {
    // 预览图片
    async handlePreview (file) {
      if (!file.url && !file.preview) {
        file.preview = await getImgB64FromURL(file.originFileObj)
      }
      this.previewImage = file.url + '?x-oss-process=image/resize,w_500,m_lfit' || file.preview
      this.previewVisible = true
    },

    // 自定义上传图片逻辑
    handleUploadImage ({ file }) {
      // 显示进度条
      this.isUpload = true
      this.percent = 1
      const speed = 100 / (file.size / 65000)
      const uploading = setInterval(() => {
        if (this.percent < 100) {
          this.percent += speed
        }
      }, 100)

      getOSSToken().then(ossToken => {
        if (ossToken) {
          uploadToOSS(file, ossToken)
            .then(url => {
              if (url) {
                clearInterval(uploading)
                this.isUpload = false
                // 获取图片信息，返回宽和高
                this.getImageInfo(file, (info) => {
                  this.form[this.field].push(newImage(this.form[this.field].length, url, info))
                })
              } else {
                this.$message.error('上传失败')
              }
            })
            .catch(() => {
              this.isUpload = false
              this.$message.error('上传失败')
            })
        } else {
          this.isUpload = false
          this.$message.error('上传失败')
        }
      }).catch(() => {
        this.isUpload = false
        this.$message.error('上传失败')
      })
    },

    // 自定义删除图片逻辑
    handleRemove (file) {
      this.form[this.field].splice(file.uid, 1)
      // 重新校正一下uid
      for (const i in this.form[this.field]) {
        this.form[this.field][i].uid = i
      }
    },

    // 上传前拦截
    handleBeforeUpload (file) {
      // this.getImageInfo(file, (info) => { console.log(11, info) })
      const isSuffixValid = /^image\/jp[e]+g|image\/png|image\/gif$/.test(file.type)
      if (!isSuffixValid) {
        this.$message.error('只能上传图片')
      }
      // const isLt2M = file.size / 1024 / 1024 < 2
      // if (!isLt2M) {
      //   this.$message.error('Image must smaller than 2MB!')
      // }
      return isSuffixValid
    },

    // 获取图片宽高
    getImageInfo (file, callback) {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = function () {
        const img = new Image()
        img.src = reader.result
        if (img.complete) {
          // eslint-disable-next-line standard/no-callback-literal
          callback({ height: img.height, width: img.width })
          return
        }
        img.onload = () => {
          // eslint-disable-next-line standard/no-callback-literal
          callback({ height: img.height, width: img.width })
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
