import { post } from './http'
import { mutation, query } from './graphql'

const api = {
  // 获取总览信息
  getOverview: () => query({
    operationName: 'getOverview',
    data: `
      todayAccessCount
      weekAccessCount
      monthAccessCount
      totalAccessCount

      todayNewUserCount
      weekNewUserCount
      monthNewUserCount
      totalUserCount

      todayDownloadCount
      weekDownloadCount
      monthDownloadCount
      totalDownloadCount

      todayNewImageCount
      totalImageCount
      todayNewStoneCount
      totalStoneCount

      todayNewTagCount
      totalTagCount
    `
  }, true),

  // 获取管理员列表
  getManagerList: (args = {}) => query({
    operationName: 'getManagerList',
    input: 'InputGetManagerList',
    data: `
      data {
        id
        name
        phoneNumber
        password
        isSuperManager
        status
        createdAt
        updatedAt
        permissionList
      }
      total
    `,
    variables: {
      args: args
    }
  }),
  // 获取管理员（自己的）信息
  getManagerInfo: () => query({
    operationName: 'getManagerInfo',
    data: `
      id
      name
      phoneNumber
      password
      isSuperManager
      status
      createdAt
      updatedAt
      permissionList
    `
  }, true),
  // 添加管理员
  addManager: (args = {}) => mutation({
    operationName: 'addManager',
    input: 'InputAddManager!',
    data: `
      id
    `,
    variables: {
      args: args
    }
  }),
  // 编辑管理员信息
  editManager: (args = {}) => mutation({
    operationName: 'editManager',
    input: 'InputEditManager!',
    data: `
      rowsAffected
    `,
    variables: {
      args: args
    }
  }),
  // 删除管理员
  delManager: (id) => mutation({
    operationName: 'delManager',
    id,
    data: `
      rowsAffected
    `
  }, true),

  // 获取所有用户
  getUserList: (args = {}) => query({
    operationName: 'getUserList',
    input: 'InputGetUserList',
    data: `
      data {
        id
        companyName
        name
        gender
        phoneNumber
        email
        password
        token
        createdAt
        updatedAt
      }
      total
    `,
    variables: {
      args: args
    }
  }),
  // 添加用户
  addUser: (args = {}) => mutation({
    operationName: 'addUser',
    input: 'InputAddUser!',
    data: `
      id
    `,
    variables: {
      args: args
    }
  }),
  // 编辑用户
  editUser: (args = {}) => mutation({
    operationName: 'editUser',
    input: 'InputEditUser!',
    data: `
      rowsAffected
    `,
    variables: {
      args: args
    }
  }),
  // 删除用户
  delUser: (id) => mutation({
    operationName: 'delUser',
    id,
    data: `
      rowsAffected
    `
  }, true),

  // 获取标签列表
  getTagList: (args = {}) => query({
    operationName: 'getTagList',
    input: 'InputGetTagList',
    data: `
      data {
        id
        name
        isColor
        isMaterial
        isTexture
        manager {
          name
        }
        createdAt
        updatedAt
      }
      total
    `,
    variables: {
      args: args
    }
  }),
  // 添加标签a
  addTag: (args = {}) => mutation({
    operationName: 'addTag',
    input: 'InputAddTag!',
    data: `
      id
    `,
    variables: {
      args: args
    }
  }),
  // 编辑标签
  editTag: (args = {}) => mutation({
    operationName: 'editTag',
    input: 'InputEditTag!',
    data: `
      rowsAffected
    `,
    variables: {
      args: args
    }
  }),
  // 删除标签
  delTag: (id) => mutation({
    operationName: 'delTag',
    id,
    data: `
      rowsAffected
    `
  }, true),

  // 获取代币记录列表
  getTokenList: (args = {}) => query({
    operationName: 'getTokenList',
    input: 'InputGetTokenList',
    data: `
      data {
        id
        source
        value
        user {
          name
        }
        manager {
          name
        }
        createdAt
        updatedAt
      }
      total
    `,
    variables: {
      args: args
    }
  }),
  // 添加代币记录
  addToken: (args = {}) => mutation({
    operationName: 'addToken',
    input: 'InputAddToken!',
    data: `
      id
    `,
    variables: {
      args: args
    }
  }),

  // 获取石材信息
  getStoneList: (args = {}) => query({
    operationName: 'getStoneList',
    input: 'InputGetStoneList',
    data: `
      data {
        id
        title
        content
        price
        totalCount
        weight
        views
        downCount
        managerID
        createdAt
        updatedAt
        image {
          url
          height
          width
          sort
          weight
        }
        imagePack {
          url
        }
        manager {
          name
        }
        images {
          url
          height
          width
          sort
          weight
        }
        tags {
          tagID
          name
        }
        contacts
      }
      total
    `,
    variables: {
      args: args
    }
  }),
  // 添加石材
  addStone: (args = {}) => mutation({
    operationName: 'addStone',
    input: 'InputAddStone!',
    data: `
      id
    `,
    variables: {
      args: args
    }
  }),
  // 编辑石材
  editStone: (args = {}) => mutation({
    operationName: 'editStone',
    input: 'InputEditStone!',
    data: `
      rowsAffected
    `,
    variables: {
      args: args
    }
  }),
  // 删除石材
  delStone: (id) => mutation({
    operationName: 'delStone',
    id,
    data: `
      rowsAffected
    `
  }, true),

  // 获取图片信息
  getImageList: (args = {}) => query({
    operationName: 'getImageList',
    input: 'InputGetImageList',
    data: `
      data {
        id
        url
        height
        width
        weight
        createdAt
        stone {
          title
        }
        tags {
          id
          name
        }
      }
      total
    `,
    variables: {
      args: args
    }
  }),
  // 编辑图片标签
  editImage: (args = {}) => mutation({
    operationName: 'editImage',
    input: 'InputEditImage!',
    data: `
      rowsAffected
    `,
    variables: {
      args: args
    }
  }),
  editImageWeight: (args = {}) => mutation({
    operationName: 'editImageWeight',
    input: 'InputEditImageWeight!',
    data: `
      rowsAffected
    `,
    variables: {
      args: args
    }
  }),

  // 获取订单信息
  getOrderList: (args = {}) => query({
    operationName: 'getOrderList',
    input: 'InputGetOrderList',
    data: `
      data {
        id
        tradeStatus
        orderNo
        transactionID
        paidAt
        totalPrice
        usedToken
        paidPrice
        username
        phoneNumber
        region
        address
        userID
        createdAt
        updatedAt
        user {
          name
        }
        orderDetail {
          title
          spec
          price
          count
        }
      }
      total
    `,
    variables: {
      args: args
    }
  }),
  // 编辑订单
  editOrder: (args = {}) => mutation({
    operationName: 'editOrder',
    input: 'InputEditOrder!',
    data: `
      rowsAffected
    `,
    variables: {
      args: args
    }
  }),
  // 删除订单
  delOrder: (id) => mutation({
    operationName: 'delOrder',
    id,
    data: `
      rowsAffected
    `
  }, true),

  // 获取Select选项列表，根据关键词搜索
  getSelectOptionList: (args = {}) => query({
    operationName: 'getSelectOptionList',
    input: 'InputGetSelectOptionList',
    data: `
      id
      name
    `,
    variables: {
      args: args
    }
  }),

  // 获取权限列表
  getPermissionList: () => query({
    operationName: 'getPermissionList',
    data: `
      id
      title
      key
      parentID
    `
  }, true),

  // 获取阿里云 OSS token
  getOSSToken: () => query({
    operationName: 'getOSSToken',
    data: `
      accessid
      host
      expire
      signature
      policy
      dir
    `
  }, true),

  // 获取日志
  getLogList: (args = {}) => query({
    operationName: 'getLogList',
    input: 'InputGetLogList!',
    data: `
      data {
        id
        category
        objectID
        stoneID
        userID
        createdAt
        stone {
          title
        }
        user {
          id
          name
          phoneNumber
        }
      }
      total
    `,
    variables: {
      args: args
    }
  }),
  getUserStoneLogList: (args = {}) => query({
    operationName: 'getUserStoneLogList',
    input: 'InputGetUserStoneLogList!',
    data: `
      data {
        stoneID
        userID
        stone {
          title
        }
        user {
          id
          name
          phoneNumber
        }
        count
      }
      total
    `,
    variables: {
      args: args
    }
  }),

  // 登录
  login: (params = {}) => post('/manager/token', params)
}

// Vue.use
const install = (Vue) => {
  if (install.installed) return
  install.installed = true

  // 定义属性到Vue原型中，这样每一个组件就可以通过this.$api.xxx(data) 去发送请求
  // Vue.prototype.$api = api
  Object.defineProperties(Vue.prototype, {
    $api: {
      get () {
        return api
      }
    },
    api: {
      get () {
        return api
      }
    }
  })
}

const getOSSToken = api.getOSSToken

export {
  install as default,
  getOSSToken
}
