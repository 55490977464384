<template>
  <div>
    <a-row>
      <!-- 第1行 -->
      <a-row>
        <a-col :span="cardSpan" @click="$router.push('/log-list')">
          <number-card
            title="日浏览数"
            :number="data.todayAccessCount"
          />
        </a-col>
        <a-col :span="cardSpan" @click="$router.push('/log-list')">
          <number-card
            title="周浏览数"
            :number="data.weekAccessCount"
          />
        </a-col>
        <a-col :span="cardSpan" @click="$router.push('/log-list')">
          <number-card
            title="月浏览数"
            :number="data.monthAccessCount"
          />
        </a-col>
        <a-col :span="cardSpan" @click="$router.push('/log-list')">
          <number-card
            title="总浏览数"
            :number="data.totalAccessCount"
          />
        </a-col>
      </a-row>

      <!-- 第2行 -->
      <a-row>
        <a-col :span="cardSpan" @click="$router.push('/user-list')">
          <number-card
            title="日新用户"
            :number="data.todayNewUserCount"
          />
        </a-col>
        <a-col :span="cardSpan" @click="$router.push('/user-list')">
          <number-card
            title="周新用户"
            :number="data.weekNewUserCount"
          />
        </a-col>
        <a-col :span="cardSpan" @click="$router.push('/user-list')">
          <number-card
            title="月新用户"
            :number="data.monthNewUserCount"
          />
        </a-col>
        <a-col :span="cardSpan" @click="$router.push('/user-list')">
          <number-card
            title="总用户数"
            :number="data.totalUserCount"
          />
        </a-col>
      </a-row>

      <!-- 第3行 -->
      <a-row>
        <a-col :span="cardSpan" @click="$router.push('/log-list')">
          <number-card
            title="日下载量"
            :number="data.todayDownloadCount"
          />
        </a-col>
        <a-col :span="cardSpan" @click="$router.push('/log-list')">
          <number-card
            title="周下载量"
            :number="data.weekDownloadCount"
          />
        </a-col>
        <a-col :span="cardSpan" @click="$router.push('/log-list')">
          <number-card
            title="月下载量"
            :number="data.monthDownloadCount"
          />
        </a-col>
        <a-col :span="cardSpan" @click="$router.push('/log-list')">
          <number-card
            title="总下载量"
            :number="data.totalDownloadCount"
          />
        </a-col>
      </a-row>

      <!-- 第4行 -->
      <a-row>
        <a-col :span="cardSpan" @click="$router.push('/image-list')">
          <number-card
            title="今日添加图片"
            :number="data.todayNewImageCount"
          />
        </a-col>
        <a-col :span="cardSpan" @click="$router.push('/image-list')">
          <number-card
            title="总图片数"
            :number="data.totalImageCount"
          />
        </a-col>
        <a-col :span="cardSpan" @click="$router.push('/stone-list')">
          <number-card
            title="今日添加石材"
            :number="data.todayNewStoneCount"
          />
        </a-col>
        <a-col :span="cardSpan" @click="$router.push('/stone-list')">
          <number-card
            title="总石材数"
            :number="data.totalStoneCount"
          />
        </a-col>
      </a-row>

      <!-- 第5行 -->
      <a-row>
        <a-col :span="cardSpan" @click="$router.push('/tag-list')">
          <number-card
            title="今日添加标签"
            :number="data.todayNewTagCount"
          />
        </a-col>
        <a-col :span="cardSpan" @click="$router.push('/tag-list')">
          <number-card
            title="总标签数"
            :number="data.totalTagCount"
          />
        </a-col>
      </a-row>
    </a-row>
  </div>
</template>

<script>
import NumberCard from '@/pages/dashboard/NumberCard'

export default {
  name: 'Dashboard',

  components: { NumberCard },

  data () {
    return {
      cardSpan: 6,

      data: {
        todayAccessCount: 100,
        weekAccessCount: 100,
        monthAccessCount: 100,
        totalAccessCount: 100,

        todayNewUserCount: 100,
        weekNewUserCount: 100,
        monthNewUserCount: 100,
        totalUserCount: 100,

        todayDownloadCount: 100,
        weekDownloadCount: 100,
        monthDownloadCount: 100,
        totalDownloadCount: 100,

        todayNewImageCount: 100,
        totalImageCount: 100,
        todayNewStoneCount: 100,
        totalStoneCount: 100,

        todayNewTagCount: 100,
        totalTagCount: 100
      }
    }
  },

  beforeMount () {
    this.getOverview()
  },

  methods: {
    // 获取总览信息
    getOverview () {
      this.api.getOverview().then(res => {
        this.data = res.data.getOverview
      })
    }
  }
}
</script>

<style scoped>
</style>
